import { CircularProgress, Grid, List, TextField } from "@mui/material";
import { MdSend } from "@react-icons/all-files/md/MdSend";
import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "../styles/booking.css";
import ChatItem from "./ChatItem";
function ChatBox({
  loader,
  action,
  driver_id,
  setAction,
  message,
  sendMsg,
  trigger,
}) {
  const LoginData = useSelector((state) => state.LgnData);
  const booking_data = useSelector((state) => state.Currentbooking);
  const [newMessage, setNewMessage] = useState("");
  const scrollDiv = useRef();
  const [channel, setchannel] = useState(null);

  var chat_identity = "customer" + driver_id + "InstaGuard";

  const styles = {
    textField: {
      width: "100%",
      borderWidth: 0,
      borderColor: "transparent",
      padding: "0 !important",
    },

    textFieldContainer: {
      flex: 1,
      marginRight: 12,

      borderRadius: 10,
    },
    gridItem: { paddingTop: 12, paddingBottom: 12 },
    gridItemChatList: {
      overflow: "auto",
      height: "35vh",
    },
    gridItemMessage: { marginTop: 5, marginBottom: 5 },
    sendButton: { border: "none" },
    sendIcon: {},
    mainGrid: {
      paddingTop: 100,
      borderWidth: 1,
      border: "2px solid rgba(0, 0, 0, 0.25)",
      padding: 10,
      borderRadius: 10,
    },
  };

  const closeModel = () => {
    setAction(false);
  };
  const sendMessage = () => {
    if (newMessage) {
      sendMsg(String(newMessage).trim());
      setNewMessage("");
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    if (scrollDiv.current) {
      const scrollHeight = scrollDiv.current.scrollHeight;
      const height = scrollDiv.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  const OnEnter = (e) => {
    if (e.key == "Enter") {
      sendMessage();
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [trigger]);
  return (
    <div>
      <Modal
        centered
        title={""}
        open={action}
        onOk={null}
        onCancel={closeModel}
        className=""
        footer={null}
      >
        <div className="d-flex justify-content-center flex-column  ">
          {loader ? (
            <div className="d-flex justify-content-center ">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="review_model">
                <div className=" base-color fs-5 text-center  fw-bold ">
                  Chat <span className="app-color">Box</span>
                </div>
                <div className="">
                  <Grid container direction="column" style={styles.mainGrid}>
                    <Grid
                      item
                      style={styles.gridItemChatList}
                      className="chat-box-scroll"
                      ref={scrollDiv}
                    >
                      <List dense={true}>
                        {message &&
                          message.map((message, i) => (
                            <ChatItem
                              key={i}
                              message={message}
                              email={chat_identity}
                            />
                          ))}
                      </List>
                    </Grid>

                    <Grid item style={styles.gridItemMessage}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          style={styles.textFieldContainer}
                          className="mess-input d-flex align-items-center"
                        >
                          <TextField
                            required
                            style={styles.textField}
                            placeholder="Enter message"
                            variant="outlined"
                            value={newMessage}
                            onKeyUp={OnEnter}
                            // disabled={channel == null}
                            onChange={(event) =>
                              setNewMessage(event.target.value)
                            }
                          // multiline
                          />
                        </Grid>

                        <Grid item>
                          <button
                            style={styles.sendButton}
                            onClick={sendMessage}
                            disabled={newMessage.length == 0}
                            className="bg-app-color rounded-2 text-white p-2 "
                          >
                            {/* <Send style={styles.sendIcon} /> */}
                            <MdSend size={25} style={styles.sendIcon} />
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>

              {/* <div className="d-flex justify-content-center  align-items-center gap-2 mt-2 mb-3">
                <button
                  className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
                  //   onClick={}
                >
                  Cancel
                </button>
                <button
                  //   onClick={handleOk}
                  className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
                >
                  OK
                </button>
              </div> */}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ChatBox;
