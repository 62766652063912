export const bookingData = (state = {}, action) => {
  switch (action.type) {
    case "addtostore":
      const values = action.payload;
      return action.payload;
    case "clearAll":
      return {};
    default:
      return state;
  }
};
