import { CircularProgress } from "@mui/material";
import { FaRegEye } from "@react-icons/all-files/fa/FaRegEye";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import { IoLocationOutline } from "@react-icons/all-files/io5/IoLocationOutline";
import { MdCancel } from "@react-icons/all-files/md/MdCancel";
import { Modal } from "antd";
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { toast } from "react-toastify";
import store from "../Redux/Store";
import FetchApi from "../config/ApiCal";
import { getStatusInfo } from "../config/Const";
import "../styles/bookingDetails.css";
export const OnGoing = ({ items, track, bottom, navigate, type, symbol, isCancel }) => {
  const [expandedIndex, setExpandedIndex] = React.useState(null);
  const [reviewModel, setreviewModel] = useState(false)
  const [selectData, setselectData] = useState({})
  const [loader, setloader] = useState(false)
  // console.log(type);
  const handleToggleReadMore = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const CancelJob = (id) => {
    setselectData(id)
    setreviewModel(!reviewModel)
  }
  const closeModel = () => {
    setloader(true);
    if(selectData?.cancel_with_driver && selectData.main_trip_id != 0){
      console.log(selectData,"cancel");

      FetchApi("scheduledcancelJobs", {
        booking_id: selectData?.main_trip_id,
        mode: "customer",
        reason: "customer cancel"
      })
        .then((e) => {
          if (e.message == "Cancelled successfully.") {
            toast.success("Job Cancel successfully.");
            setreviewModel(false)
            setloader(false);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.success(e?.message || "Could not be cancel.")
          }
        })
        .catch((e) => {
          setreviewModel(false)
          setloader(false);
          toast.error("Something Wrong")
        })
    }
    else{
      FetchApi("requestService", {
        customer_booking_id: selectData?.booking_id,
        mode: "customer_cancel",
      })
        .then((e) => {
          if (e.message == "Customer Cancel successfully.") {
            setreviewModel(false)
            setloader(false);
            toast.success("Job Cancel successfully.");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            setreviewModel(false)
            setloader(false);
            toast.success(e?.message || "Could not be cancel.")
          }
        })
        .catch((e) => {
          setreviewModel(false)
          setloader(false);
          toast.error("Something Wrong")
        })

    }

  };

  return (
    <>
      <div className="row">

        <Fade
          damping={0.2}

          // delay={500}cascade
          triggerOnce
          className="col-12 col-md-6 col-lg-6"
          duration={450}
        >
          {items.map((e, i) => {
            return (
              <div className="" key={i}>
                <div className=" main-card rounded-3 p-3 shadow-sm   m-1">
                  <div className="">
                    <div className="top-card p-1 pb-0">
                      <div className="row ">

                        <div className="col-6 ">
                          {
                            type !== 'upcoming' && (
                              <div className="d-flex align-items-center  lister-p ">
                                <p className="text-muted">{type == "unassign" ? "Request ID" : "Booking ID"}</p>
                                <p className="">:</p>
                                <p className="base-color fw-bolder ms-1">
                                  #0{e?.booking_id}
                                </p>
                              </div>
                            )
                          }

                          <div className="d-flex align-items-center m-0 lister-p ">
                            <p className="text-muted">Service</p>
                            <p className="">:</p>
                            <p className="base-color fw-bolder service_text  ms-1">
                              {e?.service_name}
                            </p>
                          </div>
                          {e?.rating > 0 && (
                            <div className="d-flex align-items-center  lister-p">
                              <p className="text-muted">
                                {" "}
                                <FaStar />
                                {e?.rating}
                              </p>
                              <p className="">:</p>
                              <p className="base-color fw-bolder ">
                                ({e?.review_count} reviews)
                              </p>
                            </div>
                          )}


                        </div>
                        <div className="col-6  p-0 d-flex justify-content-end align-items-start">
                          <div className=" m-0 p-0 align-self-start">
                            {/* {e?.total_amount > 0 && ( */}
                            <p className="font-sm  base-color fw-bolder  m-0">
                              {symbol} {e?.total_amount}

                            </p>


                            {/* {type === "history" ? (
                          <p
                            className="badge fw-normal  rounded text-response"
                            style={{
                              background: "green",
                              width: "fit-content",
                              // fontSize: 12,
                            }}
                          >
                            Completed
                          </p>
                        ) : null} */}
                            <p
                              className="text-muted font-sm m-0"
                              style={{ fontSize: 12 }}
                            >
                              {e?.job_date}

                              <br />
                              {
                                track && e.trip_type == 2 && (
                                  <p className="badge  bg-info  " style={{ fontSize: 14 }}>Upcoming</p>
                                )
                              }
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex   w-100  justify-content-between   align-items-center  "></div>
                    </div>
                    <div className="bottom font-sm">
                      <div className="p-1">
                        <div className="base-color py-1 fs-ms fs-6">
                          <IoLocationOutline />
                          Address
                        </div>
                        <p
                          onClick={() => handleToggleReadMore(i)}
                          className={`text-muted location_text  fs-ms fs-6 ms-1 p-1 pointer ${expandedIndex === i ? "expanded" : "collapsed"
                            }`}
                        >
                          {e.customer_location}
                        </p>
                      </div>
                      {
                        isCancel && (
                          <div className="d-flex justify-content-between">
                            <p className="text-response badge "
                              style={{
                                background: getStatusInfo(
                                  parseInt(e.service_status)
                                ).externalColor,
                              }}
                            >{getStatusInfo(parseInt(e.service_status)).name}</p>
                            {
                              e.service_status != 8 && (
                                <button className="btn btn-danger " onClick={() => CancelJob(e)}>
                                  <MdCancel className="me-2" />
                                  Cancel</button>
                              )
                            }
                          </div>
                        )
                      }
                      {bottom ? (
                        <div className=" ">
                          <div className="row px-3 justify-content-end ">
                            <div className="base-color col-4 p-0  pointer text-response">
                              {type === "history" ? (
                                <p
                                  className="m-0 p-0 "
                                  onClick={() => {
                                    store.dispatch({
                                      type: "addtohistory",
                                      payload: e,
                                    });
                                    navigate("/viewBooking");
                                  }}
                                >
                                  {" "}
                                  <FaRegEye className="me-1 " />
                                  View Booking
                                </p>
                              ) : null}
                              {/* {
                                type === "upcoming" &&
                                ( */}


                              {track ? (
                                <div className="d-flex justify-content-between algin-items-center">
                                  <button
                                    onClick={() => {
                                      navigate("/mapView", {
                                        state: e,
                                      });
                                      store.dispatch({
                                        type: "addtocurrent",
                                        payload: e,
                                      });
                                    }}
                                    className=" track-button rounded-5  m-0 mt-1   bg-app-color d-flex gap-1 align-items-center"
                                  >
                                    {/* <CiMap size={20} /> */}
                                    Track
                                  </button>
                                </div>

                              ) : null}
                              {/* <button className="track-button rounded-5 d-flex gap-1 align-items-center  m-0 mt-2   bg-base-color">
                            <MdOutlineDownloadDone size={15} />
                            EndTrip
                          </button> */}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Fade>

      </div>
      <Modal
        centered
        title={""}
        open={reviewModel}
        onOk={null}
        onCancel={null}
        className=""
        footer={null}
        closable={false}
      >
        <div className="d-flex justify-content-center flex-column  ">
          {loader ? (
            <div className="d-flex justify-content-center ">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="text-center fs-5 fw-bold ">
                Are you sure want to end this Job ?
              </div>
              <div className="d-flex justify-content-center  align-items-center gap-2 mt-2 mb-3">
                <button
                  className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
                  onClick={() => setreviewModel(false)}
                >
                  Cancel
                </button>
                <button
                  onClick={closeModel}
                  className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
                >
                  OK
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>

  );
};
