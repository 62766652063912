import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FetchApi from '../config/ApiCal';
import { Colors } from '../config/Colors';
import ListComponent from './ListComponent';
import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';

function Wallet() {
    const [app, setapp] = useState({
        code: 'gbp'
    });
    const [wallet, setwallet] = useState(0)
    const LoginData = useSelector((state) => state.LgnData);
    
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        ListApi()
        // return () => {
        FetchApi("appsetting").then(async (data) => {
            const currency = data?.app_setting?.default_currency;
            // console.log(data);
            await setapp({
                currency: data.currency.find((e) => e.id == currency)?.symbol,
                all: data.app_setting,
                code: data.currency.find((e) => e.id == currency)?.short_name
            });
        });
        // };
    }, []);
    const ListApi = async () => {
        const datalist = await FetchApi('profileView', { id: LoginData.id, offset: 0 });
        // console.log(datalist);
        setwallet(datalist?.data?.wallet_balance)
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const items = [
        {
            key: 1,
            label: <p className="m-0 p-0 letter-space">All</p>,
            children: (
              <ListComponent
                url={"Transaction"}
                scroll={scrollToTop}
                content={"wallet"}
                filter={"all"}
              />
            ),
          },
          {
            key: 2,
            label: <p className="m-0 p-0 letter-space">Money In</p>,
            children: (
              <ListComponent
                url={"Transaction"}
                scroll={scrollToTop}
                content={"wallet"}
                filter={"credit"}
              />
            ),
          },
          {
            key: 3,
            label: <p className="m-0 p-0 letter-space">Money Out</p>,
            children: (
              <ListComponent
                url={"Transaction"}
                scroll={scrollToTop}
                content={"wallet"}
                filter={"debit"}

              />
            ),
          },
    ]
    return (
        <div className="container mt-5" >
            <div className="d-flex my-2 justify-content-between align-items-center">
                 <h2 style={{ color: Colors.baseColor }}>Transcation<span className='mx-1' style={{ color: Colors.appColor }}> History</span></h2>
                <button
                    className="text-white  rounded-2 some_btn   bg-app-color"
                    onClick={() =>
                        navigate("/profile")
                    }
                >
                    Back
                </button>
            </div>
            <div className='my-5 mt-5 bg-white rounded-2' style={{ minHeight: '80vh' }} >
                <div className="text-center pt-4 ">
                    <div className="top fs-3 fw-bold  py-2 pb-0">
                        <span className='app-color' >{app?.currency}</span> {wallet} <span className='text-uppercase base-color'>{app?.code}</span>
                    </div>
                    <span className='letter-space base-color' style={{ letterSpacing: 2 }}>Wallet Balance</span>
                </div>
               

                <hr />
                   
                <div className="">
                    <div className='px-4'>
                    <Tabs
                        defaultActiveKey={1}
                        className="bg-white p-2 rounded-2 "
                        items={items}
                        size="large"
                    />
                    </div>

                  
                </div>
            </div>
        </div>

    )
}

export default Wallet;
export const WalletList = ({ items, symbol, filter }) => {
    if(filter != "all"){
        let status = filter == "debit" ? 0 : 1;
        items = items.filter(item => item.transaction_status == status);
    } 
    
    return (
        <div className="container">
            {
                items.map((dat, i) => {


                    return (
                        <div className="row border m-0 p-3 rounded-2 my-1 mx-1 " key={i}>
                            <div className='col-10  col-lg-10 col-md-10    text-response'>
                                {dat.transaction_notes}
                                <br />
                                {dat.created_at}
                            </div>

                            <div className="col-2  col-lg-2 col-md-2 p-0">
                                <p className='m-0 mb-1'>  {symbol}{dat.transaction_amount}</p>
                                <div className={`badge fw-normal rounded text-uppercase  text-response ${dat.transaction_type == 'debit' ? 'bg-app-color' : 'bg-base-color'}`}>
                                    {dat.transaction_type}

                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}