import React, { memo } from "react";
export const TextInput = memo(
  ({ lable, id, place, name, read = false, value = "", change, error, defaultValue, type = 'text', onFocus = null }) => {
    // console.log(all);
    return (
      <div className="m-1 p-2">
        <label
          htmlFor={id}
          className={`d-block mb-2  ${error ? "text-danger " : "text-muted"}`}
        >
          {lable}
        </label>
        <input
          type={type}
          disabled={read}
          value={value}
          id={id}
          min={0}
          autoComplete="off"
          name={name}
          onChange={change}
          className={`inp-book w-100 ${error ? "border-danger " : "border-muted"
            }`}
          placeholder={place}
          defaultValue={defaultValue}
          onFocus={onFocus}
        />
        {error ? (
          <span className="text-danger" style={{ fontSize: 12 }}>
            {" "}
            {error} *
          </span>
        ) : null}
      </div>
    );
  }
);
