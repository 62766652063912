import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import FetchApi from '../config/ApiCal';


function PrivacyPolicy() {
    const [data, setData] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        FetchApi('pages').then((data) => {
            if (data.message == "Pages listed successfully.") {
                console.log(data)
                setData(data.data[0].description)
            }
        })

    }, [])
    return (
        <div className='container'>
            <div className='work-head'><small role='button' onClick={() => {
                navigate('/')
                window.scrollTo({ top: 0, behavior: "smooth" });
            }}>Home</small>
                <IoIosArrowForward className='arr-ico' color='#2a2f83' size={22} />
                &nbsp;<span>Privacy Policy</span></div>
            <div>
                <p className='work-sub-head'>Privacy <span>Policy</span></p>
                {/* <p className='work-desc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                {/* <p className='work-sub-desc text-muted'>
                    INTRODUCTION<br></br>
                    This Privacy Policy outlines Rent-a-Guard Limited(&quot ” we  our   the Company “)
                    practices with respect to information collected from users who access our website at
                    www.rentaguard.co.uk (&quot; Site &quot;), or otherwise share personal information with us
                    (collectively: &quot; Users &quot;).
                    <br></br>
                    Grounds for data collection
                    Processing of your personal information (meaning, any information which may
                    potentially allow your identification with reasonable means; hereinafter &quot; Personal
                    Information is necessary for the performance of our contractual obligations
                    towards you and providing you with our services, to protect our legitimate interests
                    and for compliance with legal and financial regulatory obligations to which we are
                    subject.When you use the Site, you consent to the collection, storage, use, disclosure and other
                    uses of your Personal Information as described in this Privacy Policy.
                    We encourage our Users to carefully read the Privacy Policy and use it to make informed
                    decisions.<br></br>
                    What information we collect?<br></br>
                    We collect two types of data and information from Users.

                    The first type of information is un-identified and non-identifiable information pertaining
                    to a User(s), which may be made available or gathered via your use of the Site (“ Non-
                    personal Information ”). We are not aware of the identity of a User from which the Non personal Information was collected. Non-personal Information which is being collected
                    may include your aggregated usage information and technical information transmitted
                    by your device, including certain software and hardware information (e.g. the type of
                    browser and operating system your device uses, language preference, access time, etc.)
                    in order to enhance the functionality of our Site. We may also collect information on your
                    activity on the Site (e.g. pages viewed, online browsing, clicks, actions, etc.).

                    The second type of information Personal Information which is individually identifiable
                    information, namely information that identifies an individual or may with reasonable
                    effort identify an individual. Such information includes:
                </p> */}
                {
                    data ?

                        <div dangerouslySetInnerHTML={{ __html: data }} className='mt-4 work-sub-desc'>
                        </div>
                        :
                        null
                }

            </div>
        </div>
    )
}

export default PrivacyPolicy