import LocalizedStrings from "react-localization";

export let LoginString = new LocalizedStrings({
  en: {
    LoginTitle: "Login to",
    LoginGetText: "Get Started!",
    LoginSubTitle: "Login to your Account",
    LoginEmailText: "EMAIL",
    LoginMobileText: "MOBILE",
    Label1: "Email ID",
    Label2: "Password",
    LoginFP: "Forgot Password?",
    LoginBtn: "LOGIN",
    LoginDontAccnt: "Don’t have an account?",
    Register: "Register Now",

    //Register
    RegisterTitle: "Create An",
    RegisterTitle2: "Account",
    RegisterSubTitle:
      "Register for FREE and start using Rent-a-Guard in seconds!",
    RegisterFNLabel: "First Name",
    RegisterLNLabel: "Last Name",
    RegisterMNLabel: "Mobile Number",
    RegisterCPLabel: "Create Password",
    Regpswdesc:
      "Password must: Use at least 8 characters, Upper and Lower case characters, numbers, special characters (!@#$%^&*)",
    RegisterLetsGo: "LET’S GO!",
    RegAlready: "Already have an account?",
    RegLogin: "Login",
    Term: "Terms of Services",
    Poli: "Privacy Policy",

    //Forgot Password
    ForgotTitle1: "Forgot",
    ForgotTitle12: "Password",
    ForgotSubtitle:
      "Don’t worry! It happens, Please enter the email address associated with your account.",
    ForgotSubmit: "SUBMIT",

    //VerifyAccount
    VerifyTitle: "Verify",
    VerifyTitle2: "Account",
    VerifySub: "A 4 digit code has been sent to registered email",
    VerifyDidntReceive: "Didn’t receive code?",
    VerifyRA: "Request Again",
    VerifyBtn: "VERIFY",

    //Reset Password
    ResetPTitle: "Reset",
    ResetPTitle2: "Password?",
    ResetPDes:
      "Your new password must be different from previous used passwords.",
    ResetNewP: "New Password",
    ResetCP: "Confirm Password",

    //Profile
    ProfileTitle: "Hello",
    ProfileDes: "Please complete your profile details",
    ProfileUpte: "UPDATE",
    manage: "Manage Document",
    wallet: "Wallet",

  },
});
