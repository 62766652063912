import { ImPhoneHangUp } from "@react-icons/all-files/im/ImPhoneHangUp";
import React, { useState } from "react";
const Device = require("twilio-client").Device;

const TwiloCall = ({ hang, time, sts }) => {
  const [incomingConnection, setIncomingConnection] = useState(null);

  const hangUpCall = () => {
    hang();
    // setTime(0);
    // stopTimer();
    // if (twilioClient) {
    //   twilioClient.disconnectAll();
    // }
  };
  // Function to accept the incoming call
  // const acceptCall = () => {
  //   if (incomingConnection) {
  //     incomingConnection.accept();
  //   }
  // };

  // // Function to decline the incoming call
  // const declineCall = () => {
  //   if (incomingConnection) {
  //     incomingConnection.reject();
  //     setCallStatus("Call declined");
  //   }
  // };

  return (
    <div className="m-0 d-flex justify-content-center flex-column align-items-center">
      {/* <h2>Twilio Voice Calling</h2> */}
      {/* <p className="m-0">Status: {sts}</p> */}
      <p className="app-color fs-5 mb-0">{time}</p>
      {/* <button onClick={makeCall}>Make Call</button> */}
      <button
        onClick={hangUpCall}
        className="p-3 rounded-circle text-white bg-danger border-0 fs-6 "
      >
        <ImPhoneHangUp />

      </button>
      {/* <button onClick={acceptCall}>accept</button> */}
      {/* <button onClick={declineCall}>decline</button> */}
    </div>
  );
};

export default TwiloCall;
