import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import store from "../Redux/Store";
import FetchApi from "../config/ApiCal";
import { stingsTxt } from "../config/Const";
import { images } from "../config/ConstImg";
import { strings } from "../config/Strings";
import { ToastContainer,toast } from "react-toastify";
import "../styles/Home.css";
import {Fab} from '@mui/material';
import arrow_anime from '../asserts/arrow-anime.gif';
import { useSelector } from "react-redux";
function Home() {
  // const callAppSetting = async () => {
  //   const ApiResponse = await FetchApi("appsetting");
  //   store.dispatch({ type: "appsettings", payload: ApiResponse });
  // };
  // useEffect(() => {
  //   return () => {
  //     callAppSetting();
  //   };
  // }, []);

  // const rdxData = useSelector((state) => state.HomeData)

  const navigate = useNavigate();
  const LoginData = useSelector((state) => state.LgnData);

  const catVal = [
    {
      img: "../asserts/catImg01.png",
      txt: "Neighbourhood Disputes",
    },
    {
      img: "../asserts/catImg02.png",
      txt: "Nuisance Noise",
    },
    {
      img: "../asserts/catImg04.png",
      txt: "Vacant properties",
    },
    {
      img: "../asserts/catImg05.png",
      txt: "Private Land production",
    },
    {
      img: "../asserts/catImg03.png",
      txt: "Criminal Damage",
    },
    // {
    //   img: "../asserts/catImg06.png",
    //   txt: "Private Land production",
    // },
    // {
    //   img: "../asserts/catImg04.png",
    //   txt: "Vacant properties",
    // },
    // {
    //   img: "../asserts/catImg02.png",
    //   txt: "Nuisance Noise",
    // },
    // {
    //   img: "../asserts/catImg01.png",
    //   txt: "Neighbourhood Disputes",
    // },
    // {
    //   img: "../asserts/catImg03.png",
    //   txt: "Criminal Damage",
    // },
  ];

  const catVal01 = [
    {
      img: images.catImg001,
      txt: "SOS",
    },
    {
      img: images.catImg002,
      txt: "Vacant Property Protection Hubs",
    },
    {
      img: images.catImg003,
      txt: "Wedding Security Officer",
    },
    {
      img: images.catImg004,
      txt: "Residential Security",
    },

    {
      img: images.catImg005,
      txt: "Retail Security",
    },
    {
      img: images.catImg006,
      txt: "24/7 Emergency Security",
    },
    {
      img: images.catImg007,
      txt: "Transit Security ",
    },
    {
      img: images.catImg008,
      txt: "Site Security Guards",
    },
    {
      img: images.catImg009,
      txt: "Landlord In Distress",
    },
    {
      img: images.catImg010,
      txt: "Hotels and Hospitality Venues",
    },
    {
      img: images.catImg011,
      txt: "Chemists and NHS facilities",
    },
    {
      img: images.catImg012,
      txt: "Traffic Blockade Protesting",
    },
    {
      img: images.catImg013,
      txt: "Customer Service Guards",
    },
    {
      img: images.catImg014,
      txt: "Anti Racism Security",
    },
    {
      img: images.catImg015,
      txt: "Jewish Protection Officers",
    },
  ];
  const labelTxt = [
    "Write Reports",
    "Hire Experienced Professionals",
    "Collate Evidence / Surveillance",
    "Provide a Security Presence",
    "Add Reassurance & Peace of Mind",
    "Liaise with Perpetrators",
    "Contact Authorities with Evidence",
  ];
  const labelTxt01 = [
    " Automated Matching",
    " Selection of Security Personnel ",
    "Geo-Fencing and GPS",
    "     Operation of the Officer",
    "   Customer Review ",
    "  Ad Hoc Service",
    " Immediate Assistance",
    "  Customised Service",
    "System Linked to Emergency Responders"
  ];

  const handleService = (id) => {
    toast.dismiss();
    // let user = localStorage.getItem('user_id');
    let user = Object.entries(LoginData).length > 0 ? LoginData.id : null;
    if(user && user != null){
      navigate("/services");
      store.dispatch({ type: "setType", payload: id });
    }else{
      navigate("/login");
      setTimeout(() =>{
        toast.error("Login to booking", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    }
  }
  return (
    <div className="home-page">
      {/* <ToastContainer /> */}
      {/* <TwiloReceiver /> */}
      <div className="home-banner">
        {/* <div className="sales-line fs-4 fw-bold">
          <span className="">Sales Line: <a href="tel:+916383714271">07920400283</a></span>
        </div> */}
        <Slide
          damping={0.5}
          cascade
          direction="up"
          triggerOnce
          // delay={500}
          duration={800}
        >
          <div className="home-banner-text-box">
            <div className="home-banner-text-box01">
              <span className="home-banner-text-box01-txt">
                {/* {strings.homeHeadText} */}
                {/* <span className="app-color">The UK’s first automated</span>{" "}
                <span className="">Security on demand</span>{" "}
              <span className="app-color">application</span> */}
                <span className="fs-3">Whatever the occasion ……</span><br />
                <span style={{color:"#d40031"}}>HIRE SECURITY GUARDS</span>{" "}
                <span className="">INSTANTLY</span>{" "}
                {/* <span className="app-color">INSTANTLY</span> */}
              </span>
            </div>

            {/* <div className="home-banner-text-box02">
              <span className="home-banner-text-box02-txt"> */}
                {/* {strings.any_time} */}
                {/* Anytime / Anyplace / Anywhere{" "}
                <span style={{ color: "red" }}>Just Insta-guard it!</span>
              </span>
            </div> */}

            <div className="btn-div">
              <button
                className="sos-btn"
                onClick={() => {
                  handleService(1)
                  // navigate("/services");
                  // store.dispatch({ type: "setType", payload: 1 });
                }}
              >
                SOS
              </button>
              <button
                className="book-btn"
                onClick={() => {
                  handleService(2)
                  // navigate("/services");
                  // store.dispatch({ type: "setType", payload: 2 });
                }}
              >
                {strings.book_a_guard}{" "}
              </button>
            </div>
            <img className="arrow-anime" src={arrow_anime} alt="arrow-anime" />
            {/* <iframe className="arrow-anime" src="Animation - 1723029992542.json"></iframe> */}
            {/* <iframe className="arrow-anime" src="https://lottie.host/embed/38fcdb41-3875-4ae5-9300-90ca3794eda5/UxmvKmp4qE.json"></iframe> */}
          </div>
        </Slide>
      </div>
      {/* <div className="sales-line fs-4 fw-bold">
          <span className="">Sales Line: <a href="tel:+447920400283">07920400283</a></span>
      </div> */}
      <div className="home-service home-service-update">
        <div className="container my-5">
          <Slide
            damping={0.5}

            direction="up"
            // delay={500}
            triggerOnce
            duration={800}
          >
            {/* <div className="">
              <p className="home-service-content-text-box-txt ">
                What is <span className="app-color">Insta-guard?</span>
              </p>


              <div className="mb-2 row">

                <div className=" col-12 col-md-8 col-lg-8">
                  <ul className='list-style-none ul-lister'>
                    <li>
                      Welcome to Insta-Guard, the UK's first 'security on demand' application designed to address
                      the growing need for public protection. At Insta-Guard, our mission is rooted in the principles of
                      service and assistance, aiming to bridge the gap in emergency security response and support
                      individuals and businesses in times of need.  </li>
                    <li>
                      <strong> On-Demand Service:</strong> <br /> Insta-guard provides physical security services on demand. If someone feels threatened or requires immediate assistance, they can request help through the Insta-guard platform or app.
                    </li>
                    <li>
                      <strong> Transparency and Accountability:</strong><br /> Insta-guard could offer transparency by letting users know when help is on the way and providing information about the responding security personnel.
                    </li>
                    <li>
                      <strong> Quick Reaction Force:</strong><br />  Insta-guard's model emphasises a rapid response to security incidents. Much like how on demand taxi drivers are ready to pick up passengers quickly, Insta-guard's security personnel are trained to arrive at the scene within minutes to assist victims and address security concerns.
                    </li>
                    <li>
                      We understand the importance of swift action in emergency situations, which is why all our
                      security responders are on standby, equipped, and ready to act at a moment's notice.                    </li>
                    <li>
                      <strong>Peace of Mind and Confidence:</strong> <br /> Insta-guard aims to offer peace of mind and added confidence in personal safety by providing a reliable and swift response to security threats.
                    </li>
                    <li>
                      In today's world, with diminishing police resources and increasing disorder, Insta-Guard stands
                      as a beacon of assistance, providing emergency responders at critical moments. Our platform
                      utilises innovative technology to signal nearby security responders, ensuring the fastest
                      possible response time to emergencies.                    </li>
                    <li>
                      <strong>Convenience and Accessibility:</strong> <br /> Like most ‘on demand’ convenient app-based platforms, Insta-guard likely offers an easy-to-use interface for requesting assistance, making it accessible to a wide range of users who may require physical security services.
                    </li>
                    <li>
                      Insta-Guard is not just a business; it's a venture driven by a commitment to community service
                      and making a positive impact in society. We recognise that this endeavour requires a highly
                      skilled and dedicated workforce, composed of professionals who are passionate about using
                      their expertise to contribute to the safety and well-being of others.                    </li>
                    <li>If you share our vision and believe you possess the skills and dedication to become part of the
                      UK's first automated emergency responder app, we invite you to join us on this journey.
                      Together, we can make a real difference and help create a safer environment for everyone. </li>
                    <li>Overall, the comparison with On Demand Services helps to illustrate how Insta-guard's on-demand physical security model operates, emphasising quick response times, transparency, and peace of mind for users seeking added security and protection. </li>
                  </ul>
                </div>
                <div className=" col-12 col-md-4 col-lg-4">
                  <img
                    src={images.contant_01}
                    alt="train-img"
                    className="home-service-update-img"
                  />

                </div>

              </div>


            </div> */}

            <div>
              <p className="home-service-content-text-box-txt ">
                How Insta-<span className="app-color">guard works</span>
              </p>
              <div>
                <ul className="initial-content ul-lister">
                  <li>
                    <strong>1. Request </strong> <br />
                    Click the button ‘BOOK A GUARD’ or ‘SOS’ tab above & create an account. Enter the number of security personnel you require and for book a guard provide the relevant times and dates. 
                    Enter the location you wish the guards to arrive at and the description of why you are requesting the service. 
                    The guard/s will see this prior to arrival. Then press proceed.
                    <p>The system will then send notifications to all nearby security responders who will see your request on their app.</p>
                      <p> After a security professional accepts your job, payment will then be asked by yourself, and we have various payment gateways to allow you to pay.
                      You’ll see the guard’s arrival time if you hover over their details on the map.</p> 
                  </li>
                  <li>
                    <strong>2. Arrival </strong> <br />
                    The guard will arrive at the location, and you will see their GPS on maps. You can call the guard without sharing your number if you wish using VOIP system 
                    inbuilt into the app only. The security officer must have their SIA badge credentials when they arrive on display.
                    <p>Please always ask to see this.</p> 
                  </li>
                  <li>
                    <strong>3. Request </strong> <br />
                    You’ll be charged through your payment method on file automatically, if you wish to extend the hours for the guard you are able to do this in extend booking.
                    <p>Remember to rate your security guard to help keep Insta-guard safe and reputable for everyone. You can also consider tipping the security officer.</p> 
                  </li>
                </ul>
              </div>
            </div>

      
            <div>
              <p className="home-service-content-text-box-txt text-center mt-5 mb-5">
              Insta-<span className="app-color">guard</span>
              </p>
              <div className="row">
                <div className="col-md-4">
                  <img className="rounded-circle mb-3" src={images.choice} alt="test"/>
                  <h5 className="desc-head">Choice</h5>
                  <p className="desc">Get the security that’s right for you in just a few taps.</p>
                </div>
                <div className="col-md-4">
                  <img className="rounded-circle mb-3" src={images.convenience} alt="test"/>
                  <h5 className="desc-head">Convenience</h5>
                  <p className="desc">Request a security service in minutes – whenever and wherever.</p>
                </div>
                <div className="col-md-4">
                  <img className="rounded-circle mb-3" src={images.Up_front_price} alt="test"/>
                  <h5 className="desc-head">Upfront price</h5>
                  <p className="desc">Security automation at its finest. Only pay for what you use.</p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <img className="rounded-circle mb-3" src={images.professionalism} alt="test"/>
                  <h5 className="desc-head">Professionalism</h5>
                  <p className="desc">Fully licensed SIA Licenced & DBS checked security professionals are ready to help you in an Instant.</p>
                </div>
                <div className="col-md-5">
                  <img className="rounded-circle mb-3" src={images.safety} alt="test"/>
                  <h5 className="desc-head">Safety</h5>
                  <p className="desc">Insta-guard always puts your safety as a top priority.</p>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </Slide>
        </div>
      </div>

      <div className="text-center">
        <a href="/login">
          <Fab variant="extended" className="login-button">
            SignUp / Login
          </Fab>
        </a>
      </div>

      {/* Service */}

      <div className="home-service">
        <Slide
          direction="up"
          // delay={500}
          triggerOnce
          duration={800}
        >
          <span className="home-service-txt">
            {stingsTxt.security} <span>{stingsTxt.service}</span>
          </span>
          <ul className="home-service-dec">
            {stingsTxt.service_desc.map((val) => {
              return <li className="home-service-dec-txt">{val}</li>;
            })}
          </ul>
        </Slide>
        <div className="home-service-cat">
          <Fade duration={1000} triggerOnce>
            {catVal01.map((val) => {
              return (
                // <div className="home-service-cat-box">
                //   <div
                //     className="home-service-cat-box-img"
                //     style={{
                //       backgroundImage: `url(${val.img})`,
                //       backgroundPosition: "center",
                //       backgroundSize: "cover",
                //     }}
                //   ></div>
                //   <span className="home-service-cat-box-img-txt">
                //     {val.txt}
                //   </span>
                // </div>
                <div className="home-service-cat-box">
                  <div
                    className="home-service-cat-box-img"
                    style={{
                      backgroundImage: `url(${val.img})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="black"></div>
                  <div className="title">{val.txt}</div>
                  {/* <span className="home-service-cat-box-img-txt">
                    {val.txt}
                  </span> */}
                </div>
              );
            })}
          </Fade>
        </div>
        <div className="p-2 mt-4">
          <div className="help-text fs-5">Is the service you're looking for not listed on our page?<span><a href="contactUs" className="app-color"> Please let us know how we can <span className="help">help</span></a></span></div>
        </div>
        {/* Content */}
        <div className="home-service-content mb-0">
          <div className="home-service-content-text-box">
            <span className="home-service-content-text-box-txt color-blue">
              {stingsTxt.be_your_voice}
            </span>
          </div>
          <div
            className="home-service-content-label-box"
            style={{
              backgroundImage: `url(${images.contentBg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              className="home-service-content-label-box-img"
              style={{
                backgroundImage: `url(${images.cusReview})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                opacity: ".5",
              }}
            ></div>

            <div className="home-service-content-label-box-hd">
              <span className="home-service-content-label-box-hd-txt">
                {stingsTxt.automate}
              </span>
            </div>
            <div className="home-service-content-label-box-desc ">
              <div className="home-service-content-label-box-desc01">
                <ul className="home-service-content-label-box-desc01-ul">
                  {labelTxt01.map((val, i) => {
                    return (
                      <li
                        key={i + "list"}
                        className="home-service-content-label-box-desc01-ul-li"
                      >
                        {val}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <svg
                width="1"
                height="215"
                viewBox="0 0 1 215"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="home-service-content-label-box-desc01-line"
              >
                <line
                  x1="0.5"
                  y1="0.5"
                  x2="0.499991"
                  y2="214.5"
                  stroke="white"
                  stroke-opacity="0.3"
                  stroke-linecap="round"
                />
              </svg>
              <div className="home-service-content-label-box-desc02">
                <span className="home-service-content-label-box-desc02-txt1">
                  Simply, create an account, and press the SOS button and a
                  notification will be sent out to all security professionals
                  within your area whose job is to arrive at your location in an
                  instant.
                </span>
              </div>
            </div>
            <span className="home-service-content-label-box-desc01-ul-li">

              <strong>  Placing an Order: </strong> Users create an account on the Insta-guard app. When they need assistance, they simply press the SOS button within the app.
            </span>
          </div>
        </div>
      </div>
      <div className="my-3 container">
        <div className="text-center base-color home-service-txt custom-head  ">
          How can Book a guard help me?
        </div>
        <ul className='list-style-none ul-lister'>
          <li>
            Introducing Book a Guard – a service that empowers users to secure the protection they need
            promptly and efficiently. Whether you require a security responder to watch over your property
            while you're away or seek added assurance for your assets, animals, or loved ones, book a
            Guard is purpose-built for your peace of mind.
          </li>
          <li>
            With Book a Guard, you have the power to prioritise security on your terms. Simply place an
            order for a security responder, and rest assured knowing that your security needs will be met
            with the utmost care and attention. Whether it's safeguarding your property, providing
            protection for your valuables, or ensuring the safety of your loved ones, book a Guard is here to
            deliver heightened security solutions tailored to your specific requirements.
          </li>
          <li>
            Experience the confidence and reassurance that comes with knowing your security concerns
            are in capable hands. Book a Guard – your trusted partner for enhanced security and peace of
            mind.
          </li>
        </ul>

      </div>
      <Slide
        damping={0.3}
        cascade
        direction="up"

        triggerOnce
        duration={800}
      >

        <div className="home-service home-service-update">


          <div className="home-service-content home-service-content-update mt-0">


            {/*<p className="home-service-txt about-us-box01-left text-center mb-5">
              SOS <span>Response</span>
            </p>*/}
            <div className="row">
              <div className="col-12 col-md-7 col-lg-7">
                <div className="about-us-box-play-box01-left">

                  <span className="home-service-txt about-us-box01-left my-2">
                    Instant Response
                  </span>
                  <p className="about-us-box-play-box01-left-desc custom-head">With its extensive network of security operatives and efficient notification system, Insta-guard ensures an immediate response to emergency situations. This instant response capability is vital in providing timely assistance to users facing security threats or emergencies, helping to mitigate risks and ensure their safety.</p>

                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-5">

                <img
                  src={images.sos}
                  className="img-fluid"
                  style={{ borderRadius: "20px", alignSelf: 'center' }}
                />
              </div>

              <div className="col-12 col-md-5 col-lg-5 p-3">
                <img
                  src={images.map}
                  className="img-fluid"
                  style={{ borderRadius: "20px", alignSelf: 'center' }}
                />
              </div>
              <div className="col-12 col-md-7 col-lg-7 p-3 ">
                <div className="about-us-box-play-box01-left">
                  <span className="home-service-txt about-us-box01-left">
                    Customised Service
                  </span>
                  <span className="about-us-box-play-box01-left-desc custom-head">
                    Insta-guard offers personalized security solutions tailored to the specific needs and preferences of its users. Whether it's providing close protection, escort services, or responding to emergency incidents, Insta-guard ensures that the security services provided meet the unique requirements of each situation, enhancing effectiveness and customer satisfaction.
                  </span>

                </div>
              </div>

            </div>






            {/* <div className="about-us-box-play-box01" style={{ margin: "3% 0" }}>
              <div className="about-us-box-play-box01-left">
                <span className="home-service-txt about-us-box01-left">
                  Our aim is to respond in minutes
                </span>
                <span className="about-us-box-play-box01-left-desc">
                  Notifications are sent out to close protection officers within
                  that area many of them are ex police professionals or
                  ex-servicemen and women who are highly skilled within the
                  security sector
                </span>
              </div>
              <img
                src={images.sos002}
                className="about-us-box-play-box03-right spl-work-img"
                style={{ borderRadius: "20px" }}
              />
              <img
                src={images.sos001}
                className="about-us-box-play-box03-right spl-work-img"
                style={{ borderRadius: "20px" }}
              />
            </div>

            <div
              className="about-us-box-play-box02"
              style={{ marginTop: "0", marginBottom: "3%" }}
            >
              <div className="about-us-box-play-box01-left">
                <span className="home-service-txt about-us-box01-left">
                  Assignment of the operation
                </span>
                <span className="about-us-box-play-box01-left-desc">
                  It is essential you give detailed specifics of the job prior
                  to the officers arriving so that they are briefed and can plan
                  effectively. We have installed Twilio into the app which lets
                  you make a call to the officers prior to them arriving at your
                  location.
                </span>

                <div className="" style={{ margin: "1%" }}>
                  <li className="app-color">Is there a threat to life?</li>
                  <li className="app-color">
                    Do you want to plan and work your own work diary?
                  </li>
                  <li className="app-color">
                    Is the assailant still at the address?
                  </li>
                </div>
                <span
                  className="about-us-box-play-box01-left-desc"
                  style={{ marginTop: "0%" }}
                >
                  Are there weapons that can injury the officers?
                </span>
              </div>
              <img
                src={images.contant_02}
                className="about-us-box-play-box03-right spl-work-img"
                style={{ borderRadius: "20px" }}
              />
            </div> */}

          </div>
        </div>
      </Slide>
      <div className="home-sec-type">
        <Slide
          damping={0.3}

          direction="up"
          // delay={500}
          triggerOnce
          duration={800}
        >
          <span className="home-about-txt home-sec-type-txt">
            What type of security do you use?
          </span>
          <div className="home-sec-img-box">
            <img
              src={images.seclist01}
              alt="security images"

              className="home-sec-img-box-img home-sec-img-01 "
            />
            <img
              src={images.seclist02}
              alt="security images"
              style={{
                objectPosition: 'center'
              }}
              className="home-sec-img-box-img home-sec-img-02"
            />
            <img
              src={images.secitem04}
              alt="security images"
              className="home-sec-img-box-img home-sec-img-03"
            />
            <img
              src={images.secitem05}
              alt="security images"
              className="home-sec-img-box-img home-sec-img-04"
            />
            {/* <img
            src={images.secitem03}
            alt="security images"
            className="home-sec-img-box-img home-sec-img-04"
          /> */}
          </div>
          <div className="home-sec-desc flex-column">
            <span className="home-sec-desc-txt">
              Insta-Guard takes pride in its commitment to hiring the most elite security professionals, especially Close Protection operatives, many of whom boast backgrounds in esteemed branches of the armed forces or law enforcement.
            </span>
            <p className="mt-2 home-sec-desc-txt">
              This carefully curated selection process ensures that our team possesses the specialized skill sets and extensive experience necessary to deliver unparalleled security services to our clients. With Insta-Guard, rest assured that your safety and security are in the hands of the very best professionals in the industry.
            </p>
            <p className="home-sec-desc-txt mt-2">
              At Insta-Guard, we are committed to providing the highest level of security services, and our
              team of security responders reflects this dedication to excellence. While we value the
              experience and professionalism often found in individuals with backgrounds in law
              enforcement, uniformed services, and the military, we believe that effective security is about
              more than just credentials.
            </p>


          </div>
          <div className="">
            <span className="home-about-txt home-sec-type-txt">
              Automated Notification & Swift Response
            </span>
            <p className="home-sec-desc-txt">
              At the heart of Insta-Guard's platform lies a pivotal feature the capability for our security personnel to swiftly summon additional support when necessary. This functionality is seamlessly integrated through an SOS button embedded within the Insta-Guard application, exclusively available to our esteemed security professionals.  </p>
            <p className="home-sec-desc-txt">
              With just a single tap, our team members can activate this automated notification system, ensuring a rapid and coordinated response to any emerging security concerns or incidents. This robust mechanism exemplifies Insta-Guard's unwavering commitment to proactive safety measures and our dedication to providing unparalleled security services to our clients.
            </p>
            <p className="home-sec-desc-txt mt-2">
              We understand the importance of community engagement and empowerment, which is why we
              prioritise individuals who have deep roots within the community and a genuine desire to make a
              positive impact. Our team consists of long-standing security professionals who have become
              integral parts of the fabric of London, and who share our vision for creating a safer and more
              secure environment for all.</p>
          </div>
        </Slide>
        <Slide
          damping={0.3}

          direction="up"
          // delay={500}
          triggerOnce
          duration={800}
        >
          <div className="terrific-content-box">
            <span className="home-about-txt home-sec-type-txt">
              Specific <span className="app-color">is terrific.</span>
            </span>
          </div>
          <div className="terrific-content-img-box my-4">
            <img
              src={images.police003}
              className="terrific-content-img"
              alt="police-image"
            />
            {/*<img
              src={images.police02}
              className="terrific-content-img part-two-none police-img-spl"
              alt="police-image"
            />

           */}
          </div>
          <span className="home-sec-desc-txt  mt-2">
            Neil's story exemplifies how Insta-guard's platform empowers experienced professionals like him to play a crucial role in providing emergency response services to their communities. By leveraging Neil's expertise as a former police constable and K-9 handler, Insta-guard enhances its ability to deliver rapid, reliable, and effective security assistance, contributing to the safety and security wherever he is appointed.
          </span>
          <p className="home-sec-desc-txt mt-2 ">
            All our staff members are highly skilled and trained to meet the rigorous standards set by the
            Security Industry Authority (SIA). We are also committed to continuous improvement and
            development, which is why we have established training programs specifically tailored to
            address and strengthen areas where increased protection is needed.
          </p>
          <p className="home-sec-desc-txt  mt-2">
            By harnessing the expertise and dedication of our security responders, we aim to not only
            provide effective security solutions but also to foster positive change and contribute to the wellbeing of the communities we serve. At Insta-Guard, we believe that by working together, we can
            create a safer and more secure future for everyone.
          </p>
          <p className="home-sec-desc-txt  bottom-text mt-2">
            Overall, Insta-guard's focus on specific features such as instant response and customised service, combined with the dedication and expertise of professionals like Neil, underscores its commitment to delivering high-quality security assistance to users across London, ultimately contributing to enhanced safety and security for all.
          </p>

        </Slide>
      </div>
    </div>
  );
}

export default Home;
