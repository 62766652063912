import { Backdrop, CircularProgress } from "@mui/material";
import { IoMdArrowDropleftCircle } from "@react-icons/all-files/io/IoMdArrowDropleftCircle";
import { IoWalletSharp } from "@react-icons/all-files/io5/IoWalletSharp";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FetchApi from "../config/ApiCal";
import { STRIPE_CLIENT_ID, imageUrl } from "../config/Const";
import "../styles/PaymentScreen.css";
import Gpay from "./Gpay";
import Paypal from "./Paypal";
const PaymentScreen = () => {
  const location = useLocation();
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(STRIPE_CLIENT_ID)
  );
  function formatNumber(number) {
    if (Number.isInteger(number)) {
      return number.toString();
    } else {
      return number.toFixed(1);
    }
  }
  // coole.log(location.state, "state");
  const navigate = useNavigate();
  const [bookLoader, setbookLoader] = useState(false);
  const [paypalRes, setpaypalRes] = useState(null);
  const [stripeRes, setstripe] = useState(null);
  const [gpay, setgpay] = useState(null);
  const [payType, setPayType] = useState(null);//for apple and gpay only

  const data = useSelector((state) => state.booking);
  // console.log(data);
  const [app, setapp] = useState({
    code: 'gbp'
  });
  const allPost = { ...data };
  // console.log(allPost, "alllllll");
  allPost.vat_amount = data?.before_vat_amount;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // return () => {
    FetchApi("appsetting").then(async (data) => {
      const currency = data?.app_setting?.default_currency;
      await setapp({
        currency: data.currency.find((e) => e.id == currency)?.symbol,
        all: data.app_setting,
        code: data.currency.find((e) => e.id == currency)?.short_name
      });
    });
    // };
  }, []);
  // console.log(app);
  useEffect(() => {
    if (stripeRes != null) {
      // console.log("resP str");
      paymentDone("stripe");
    }
    if (paypalRes != null) {
      paymentDone("paypal");
    }
    if (gpay != null) {
      paymentDone("gpay");
    }
  }, [stripeRes, paypalRes, gpay]);
  // useEffect(() => {
  //   if (paypalRes != null) {
  //     paymentDone("paypal");
  //   }
  // }, [paypalRes]);

  const paymentDone = (type) => {
    // console.log("pay");
    setbookLoader(true);
    if (type == "paypal") {

      // toast.warn("paypal");
      allPost.payment_type = "paypal";
      allPost.strip_token = paypalRes?.paymentID;
    } else if (type == "stripe") {
      
      allPost.payment_type = "stripe";
      allPost.strip_token = stripeRes?.id;
    } else if (type == "gpay") {
      if(payType == "apple"){
        allPost.payment_type = "apple";
        allPost.strip_token = gpay?.id;
      }else if(payType == "stripe_gpay"){
        allPost.payment_type = "stripe_gpay";
        allPost.strip_token = gpay?.id;
      }else{
        allPost.payment_type = "stripe_gpay";
        allPost.strip_token = gpay?.id;
      }
     
    } else if (type == 'wallet') {
      allPost.payment_type = "wallet";
      allPost.strip_token = '';
    }
    // alert(allPost.strip_token);
    FetchApi("requestService", allPost)
      .then((res) => {
        if (res.message == "Request saved successfully.") {
          // console.log(app.all.timedelay_accept, "settt");
          if (allPost.trip_type == 1) {
            setbookLoader(false);

            navigate("/services/booking/loading", {
              state: {
                timeout: app.all.timeout_delay,
                booking_id: res.service_request.booking_id,
              },
            });
          } else {
            toast.success("Job added successfully");
            setbookLoader(false);

            // navigate("/services/booking/loading", {
            //   state: {
            //     timeout: app.all.timedelay_accept,
            //     booking_id: res.service_request.booking_id,
            //   },
            // });
            setTimeout(() => {
              navigate("/bookingDetails", {
                state: 2,
              });
              setbookLoader(false);
            }, 2000);
          }
        } else {
          // console.log("toast calkked", res.message);
          toast.error(res.message == 'Driver is not available' ? 'No guards are available within your area currently' : res.message);
          setTimeout(() => {
            navigate("/services");
            setbookLoader(false);
          }, 2000);
        }
        // console.log(res, "ress");
        // store.dispatch({
        //   type: "clearAll",
        //   payload: {},
        // });
      })
      .catch((e) => {
        toast.error('Something Went Wrong')
        setbookLoader(false);
      });
  };
  // console.log(paypalRes);
  return (
    <div>
      <Backdrop
        style={{
          color: "#fff",
          zIndex: 999,
        }}
        open={bookLoader}
        className=""
      >
        {/* <CircularProgress /> */}
        <div>
          <div
            className="d-flex align-items-center gap-3 p-3 px-5 text-white letter-space"
            style={{
              background: "rgba( 131, 43, 103, 0.55 )",
              boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
              backdropFilter: "blur( 20px )",
              // "-webkit-backdrop-filter": "blur( 20px )",
              borderRadius: "10px",
              border: "1px solid rgba( 255, 255, 255, 0.3 )",
              height: 100,
            }}
          >
            <CircularProgress
              style={{
                color: "#2a2f83",
              }}
            />{" "}
            Loading
          </div>
        </div>
      </Backdrop>
      {/* <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={5}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
      <div className="payment-con">
        <div className="payment-box">
          <div className="payment-hd-txt">
            <span className="home-service-content-text-box-txt">
              Request <span className="app-color">Payment</span>
            </span>
            {/* <span className="payment-desc-txt app-desc-txt-color">
              This is a fare summary for this risk assessment
            </span> */}
          </div>
          <div className="">
            <button
              className="text-white  rounded-2 some_btn   bg-app-color"
              onClick={() =>
                navigate("/services/booking", {
                  state: data?.category,
                })
              }
            >
              <IoMdArrowDropleftCircle />
              Back
            </button>
          </div>
          <div className="payment-details">
            <img
              src={imageUrl + 'uploads/' + data?.service_image}
              alt="profile-img"
              className="payment-img"
            />
            <span className="text-white letter-space mt-2">
              {data?.service_name}
            </span>

            <span className="fee-hd mt-0 "> Fare Summary</span>
            <div className="fee-box">
              {/* {data.map((val) => {
              return ( */}
              <div className="fee-details">
                <div className="fee-keys01">Insta Guard Fee</div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {app?.currency} {formatNumber(data?.sub_total)}
                </div>
              </div>
              {
                /*
              
              <div className="fee-details">
                <div className="fee-keys01">Guard's Fare</div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {" "}
                  {app?.currency} {data?.total_guard_amount}
                </div>
              </div>
              <div className="fee-details">
                <div className="fee-keys01">Total Hour</div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {data?.total_hrs} {data.total_hrs > 1 ? "hours" : "hour"}
                </div>
              </div>
              */}
              <div className="fee-details">
                <div className="fee-keys01">
                  VAT ({app?.all?.vat_percentage} %)
                </div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {" "}
                  {app?.currency} {formatNumber(data?.before_vat_amount)}
                </div>
              </div>
              {/* {data?.subscription_status && (
                <div className="fee-details">
                  <div className="fee-keys01">Discount (-)</div>
                  <div className="fee-keys02">-</div>
                  <div className="fee-keys03">
                    {app?.currency} {data?.subscription_amount}
                  </div>
                </div>
              )} */}

              <div className="fee-details">
                <div className="fee-keys01"> Total </div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {app?.currency} {formatNumber(data?.overall_total)}
                </div>
              </div>
            </div>
            {
              data?.subscription_status && (
                <p className='text-white'>Subscription Discount Applied *</p>
              )
            }
            <button onClick={() => paymentDone('wallet')} disabled={!data?.wallet_status} className="btn text-white rounded-5 mx-2 d-flex py-2 justify-content-center gap-2 align-items-center btn-info my-2" style={{ fontSize: 12, width: '95%' }}>
              <IoWalletSharp size={25} style={{ alignSelf: 'flex-start' }} />
              <div className="">
                <p className="m-0 p-0" >    Pay with Wallet </p>
                <p className="m-0 p-0" >Available Balance:  {app?.currency}{data?.wallet_amount}</p>

              </div>

            </button>
            <Paypal
              type={"stripe"}
              amount={data?.overall_total}
              getToken={setstripe}
              symbol={app?.currency}
            />

            {app?.all?.paypal_status == 1 && (
              <Paypal
                type={"paypal"}
                amount={data?.overall_total}
                getToken={setpaypalRes}
              />
            )}
            {/* <ApplePayButton /> */}
            <Elements stripe={stripePromise}>
              <Gpay amount={data?.overall_total} getToken={setgpay} currency={String(app.code).toLowerCase()} setPayType={setPayType}/>
            </Elements>

            <span className="text-white align-self-start ">Note *</span>
            <p className="text-white m-0  ">
              The amount will only be debited from your account once the guard
              accepts your request.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentScreen;
