import logo from '../src/asserts/header-logo.png'
import user from '../src/asserts/user.png'
import sendIcon from '../src/asserts/send-icon.png'
import marker from '../src/asserts/marker.png'



export const images = {
    logo: logo,
    user:user,
    sendIcon:sendIcon,
    marker:marker
};
