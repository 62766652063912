import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import * as React from "react";
// CustomInput component that receives the inputProps and passes them to an input element
const CustomInput = ({ inputProps, value, onClick, focus }) => {
  const handleKeyDown = (e) => {
    // Prevent typing by disabling key events
    e.preventDefault();
  };
  return (
    <input
      {...inputProps}
      value={value}
      onClick={onClick}
      placeholder="asffafasf"
      readOnly={true}
      onFocus={focus}
      onKeyDown={handleKeyDown}
      style={{ cursor: "pointer" }}
    />
  );
};

export default function DatePicker({
  mdate,
  lable,
  change,
  disabled = false,
  value,
  focus,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={["DateTimePicker", "DateTimePicker"]}
        className="m-0 p-0"
      >
        {/* <DateTimePicker
          // slotProps={{
          //   textField: {
          //       readOnly: true,
          //   }},
          slotProps={{
            textField: {
              readOnly: true,
            },
          }}
          format="DD-MM-YYYY HH:mm"
          label={label}
          minDateTime={mdate}
          ampm={false}
          onChange={change}
          value={value}
          disabled={disabled}
          renderInput={(props) => <CustomInput {...props} />}
        /> */}
        <DemoItem className="m-0 p-0">
          <MobileDateTimePicker
            placeholder="asasa"
            slotProps={{
              textField: {
                placeholder: lable,
                FormHelperTextProps: "wwdwdwdwd",
              },
            }}
            onOpen={focus}
            minDateTime={mdate}
            onChange={change}
            value={value}
            disabled={disabled}
            ampm={false}
            format="DD-MM-YYYY HH:mm"
            // format="DD-MM-YYYY  h:mm A"
            renderInput={(props) => <CustomInput {...props} />}
            // minuteStep={30}
            minutesStep={30}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
