import { HiClock } from "@react-icons/all-files/hi/HiClock";
import { IoLocationOutline } from "@react-icons/all-files/io5/IoLocationOutline";
import { IoShieldCheckmarkOutline } from "@react-icons/all-files/io5/IoShieldCheckmarkOutline";
import { MdDescription } from "@react-icons/all-files/md/MdDescription";
import dayjs from "dayjs";
import React from "react";
import { Fade } from "react-awesome-reveal";
import { getStatusInfo } from "../config/Const";
import "../styles/bookingDetails.css";
export const OnRequest = ({ items, symbol }) => {
  const [expandedIndex, setExpandedIndex] = React.useState(null);

  const handleToggleReadMore = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="">
      <div className="row">
        <Fade
          damping={0.3}
          cascade
          // delay={500}
          triggerOnce
          className="col-12 col-md-6 col-lg-6"
          duration={300}
        >
          {items.map((e, i) => {
            return (
              <div className="" key={i}>
                <div className=" main-card rounded-3 p-3 shadow-sm   m-1">
                  <div className="">
                    <div className="top-card d-flex p-1 justify-content-between align-items-center">
                      <div className="align-self-start font-sm ">
                        <div className="left text-muted  ">
                          Booking ID :
                          <span className="fw-bold  base-color">
                            #0{e?.booking_id}
                          </span>
                        </div>
                        <div className="text-muted text-response ">
                          {e?.job_date}
                        </div>
                      </div>
                      <div className=" ">
                        <div className="left fs-5 base-color fw-bold">
                          {symbol} {e?.total_amount}
                        </div>

                        <div
                          className="badge fw-normal rounded bg-app-color text-response"
                          style={{
                            background: getStatusInfo(
                              parseInt(e.service_status)
                            ).externalColor,
                          }}
                        >
                          {/* {e.status_text} */}
                          {getStatusInfo(parseInt(e.service_status)).name}
                        </div>
                      </div>
                    </div>
                    <div className="bottom  mt-2">
                      <div className="">
                        <div className="base-color ">
                          <IoLocationOutline className="me-1" />
                          <span className="text-response">Loaction</span>
                        </div>
                        {/* className="text-muted location_text text-response ms-1 " */}
                        <p
                          onClick={() => handleToggleReadMore(i)}
                          className={`text-muted location_text text-response  ms-1 p-1 pointer ${expandedIndex === i ? "expanded" : "collapsed"
                            }`}
                        >
                          {e?.customer_location}{" "}
                        </p>
                      </div>
                      <div className="bottom ">
                        <div className="">
                          <div className="base-color  ">
                            <MdDescription className="align-self-start me-1" />
                            <span className="text-response">
                              Special Notes{" "}
                            </span>
                          </div>
                          <p className="text-muted ms-1 p-1  text-response">
                            {e.special_instruction
                              ? e.special_instruction
                              : "----"}
                          </p>
                        </div>
                        <div className="">
                          <div className="base-color  ">
                            <IoShieldCheckmarkOutline className="align-self-start me-1" />

                            <span className="text-response">Service </span>
                          </div>
                          <p className="text-muted ms-1 p-1 text-response">
                            {e?.service_name}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <div className="">
                          <div className=" d-flex gap-1 text-response align-items-center base-color ">
                            <HiClock />
                            Preferred Date & Time
                          </div>
                          <div className="text-muted ms-3 text-response">
                            {e?.job_date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Fade>
      </div>
    </div>
  );
};
