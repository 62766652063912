import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useState } from "react";
import { STRIPE_CLIENT_ID } from "../config/Const";
import { images } from "../config/ConstImg";
import "../styles/PaymentScreen.css";
import CheckoutForm from "./CheckoutForm";

// Stripe.js will not be loaded until `loadStripe` is called
// const stripe = await loadStripe(STRIPE_CLIENT_ID);

export default function Onlystripe({
  type,
  getToken = null,
  amount = 0,
  symbol = "",
  click = null,
  overlay = true,
}) {
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(STRIPE_CLIENT_ID)
  );

  return (
    <div className="container ">
      <div className="">
        <div
          className="modal fade hide_model"
          id="card-saves"
          data-bs-backdrop="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header logout-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Pay With Stripe
                </h1>
                <button
                  type="button"
                  className="btn-close logout-close-btn"
                  data-bs-dismiss="modal"
                  id="card-close"
                ></button>
              </div>
              <div className="modal-body">
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    amount={amount}
                    setToken={getToken}
                    symbol={symbol}
                  />
                </Elements>
              </div>
            </div>
          </div>
        </div>

        <div
          className="add_card "
          data-bs-toggle="modal"
          onClick={click}
          data-bs-target="#card-saves"
        >
          <img
            src={images.stripe}
            alt="stripe-img"
            className="payment-stripe-img"
          />
          <span>PAY NOW WITH STRIPE </span>
        </div>
      </div>
    </div>
  );
}
