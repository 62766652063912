import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { CircularProgress } from "@mui/material";
import { IoIosRefresh } from "@react-icons/all-files/io/IoIosRefresh";
import { Button, Checkbox, Modal, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import FetchApi from "../config/ApiCal";
import { Colors } from "../config/Colors";
import { imageUrl } from "../config/Const";
import "../styles/bookingDetails.css";
function ManageDocument() {
  const LoginData = useSelector((state) => state.LgnData);

  const [app, setapp] = useState(null);
  const [model, setmodel] = useState(false);
  const [loading, setloading] = useState(true);
  const [docloading, docsetloading] = useState(false);
  const [document, setdocument] = useState([]);
  // const [imageUrl, setImageUrl] = useState("");
  const [uploadLoad, setuploadLoad] = useState("");
  const [agree, setagree] = useState({
    agree: false,
    ischeck: false,
    animi: false,
  });
  // console.log(agree, "agree");
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    // console.log(file.type, "type");
    const isJpgOrPng =
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
    }
    const isLt2M = file.size / 2048 / 2048 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info, i, data) => {
    // console.log(data, "oik");
    // if (info.file.status === "uploading") {
    document[i].loading = true;

    setdocument([...document]);
    //   return;
    // }
    // if (info.file.status === "done") {
    // Get this url from response in real world.
    const source = info.file.originFileObj;
    const proof_image = source?.type.split("/")[1];
    console.log(proof_image);
    const type = proof_image !== "pdf" ? "image" : "pdf";
    const reader = new FileReader();

    reader.onload = function (e) {
      const base64String = e.target.result;
      const base64Data =
        type == "pdf"
          ? base64String.replace(/^data:application\/pdf;base64,/, "")
          : base64String.replace(/^data:image\/\w+;base64,/, "");
      // console.log(base64Data);

      const obj = {
        customer_id: LoginData?.id,
        image: base64Data,
        document_type: data?.document_name,
        document_type_ar: data?.document_name,
        document_id: data?.id,
        type,
        expiry_date: data?.expire_on,
      };
      // console.log(obj, "send");
      FetchApi("WebCustomerDocumentUpdate", obj)
        .then((res) => {
          console.log(res, "op");
          listDocumnet();
        })
        .catch((e) => toast.error(e));
      // console.log("Base64 representation:", base64String);
      // You can use the base64String as needed, for example, send it to the server.
    };
    reader.readAsDataURL(source);
  };
  const listDocumnet = () => {
    docsetloading(true);
    FetchApi("WebManageCustomerDocument", {
      customer_id: LoginData?.id,
    })
      .then(({ data }) => {
        const dataModif = data.map((e, i) => {
          return {
            ...e,
            loading: false,
            proof: i == 1 ? "uploads/CustomerProof/1704365019.png" : null,
            status: i == 1 ? 1 : 0,
          };
        });
        console.log(data, "main");
        setdocument(dataModif);
        docsetloading(false);
        setagree({
          ...agree,
          ischeck: data.length > 0 && data.some((e) => e.approvedStatus != "3"),
        });

        // console.log(data, "apdp");
      })
      .catch((e) => toast.error(e));
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    FetchApi("appsetting").then((data) => {
      setapp(data.app_setting?.document_norms);
      setloading(false);
      // console.log(data, "apdp");
      listDocumnet();
    });
  }, []);
  const handleClose = (e) => {
    setmodel(false);
    setagree({ ...agree, ischeck: false });
  };
  const needToagree = () => {
    if (agree.ischeck) {
      setagree({ ...agree, agree: true });
    } else {
      // setagree({ ...agree, animi: true });
      toast.warning("Need to agree document norms ", {
        progress: undefined,
      });
    }
    // console.log(agree);

    // clearInterval(timecontrol);
  };
  const onclickAgree = (e) => {
    // console.log(e.target.checked, "fefe");
    if (e.target.checked) {
      setmodel(true);
    } else {
      setagree({ ...agree, ischeck: false });
    }
  };
  const agreeWithall = () => {
    // console.log("sfsf");
    setmodel(false);
    setagree({ ...agree, ischeck: true });
  };

  const returnUi = (task, i) => {
    if (task.approvedStatus == 3) {
      return (
        <Upload
          key={i}
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          accept="image/*"
          showUploadList={false}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          beforeUpload={beforeUpload}
          onChange={(e) => handleChange(e, i, task)}
        >
          <button
            key={i}
            style={{
              border: 0,
              background: "none",
            }}
            type="button"
          >
            {task.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </button>
        </Upload>
      );
    } else if (task.approvedStatus == 0 || task.approvedStatus == 2) {
      return (
        <Upload
          key={i}
          name="avatar"
          listType="picture-card"
          className="avatar-uploader my-1 me-3 p-0"
          showUploadList={false}
          accept="image/*"
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          beforeUpload={beforeUpload}
          onChange={(e) => handleChange(e, i, task)}
        >
          <button
            key={i}
            style={{
              border: 0,
              background: "none",
            }}
            type="button"
          >
            <div className="flex proof_main">
              {task.docType == "image" ? (
                <img
                  className="proof_image"
                  src={imageUrl + task.documentProof}
                  alt="Image Description"
                />
              ) : (
                <iframe
                  title="proof"
                  src={imageUrl + task.documentProof}
                  style={{ overflow: "hidden" }}
                  className="iframe proof-image"
                ></iframe>
              )}
              <p
                className={`proof_lable bg-secondary ${task.approvedStatus == 0
                  ? "bg-secondary"
                  : task.approvedStatus == 2
                    ? "bg-danger "
                    : ""
                  }
            `}
              >
                {task.approvedStatus == 0
                  ? "Pending"
                  : task.approvedStatus == 2
                    ? "Rejected"
                    : ""}
              </p>
            </div>
          </button>
        </Upload>
      );
    } else {
      return (
        <div className="flex proof_main">
          {task.docType == "image" ? (
            <img
              className="proof_image"
              src={imageUrl + task.documentProof}
              alt="Image Description"
            />
          ) : (
            <iframe
              className="iframe"
              height={150}
              width={150}
              // style={}
              srcDoc={imageUrl + task.documentProof}
            ></iframe>
          )}
          <p
            className={`proof_lable bg-success 
            }`}
          >
            Approved
          </p>
        </div>
      );
    }
  };

  return (
    <div className="container  mt-5 rounded-2  " style={{ minHeight: "50vh" }}>
      <div className="">
        <div className="p-1 my-2 d-flex justify-content-between">
          <div className="">
            <h2 style={{ color: Colors.baseColor }}>
              Manage
              <span className="ms-1" style={{ color: Colors.appColor }}>
                Document
              </span>
            </h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
          </div>
          <div
            onClick={listDocumnet}
            className="px-2 p-2 rounded-2 pointer bg-app-color text-white  d-flex align-items-center"
            style={{ height: "fit-content" }}
          >
            {" "}
            <IoIosRefresh className="me-2" />
            Refresh
          </div>
        </div>
      </div>
      <div className="bg-white rounded-2 p-2">
        <div className="d-flex justify-content-center">
          {docloading ? <CircularProgress /> : null}
        </div>
        <div className="content position-relative">
          {!agree.ischeck && (
            <div className="banner-agree" onClick={needToagree}></div>
          )}

          {document.map((task, i) => {
            return (
              <div className="d-flex justify-content-between p-3 border-1 align-items-center my-2 ">
                <div className="">{task.document_name}</div>
                <div className="">{returnUi(task, i)}</div>
              </div>
            );
          })}
        </div>
        <div className={`text-center checkbox-agree`}>
          <Checkbox checked={agree.ischeck} onChange={onclickAgree}>
            <span className={`text-danger fs-5`}>
              I agree to the read document norms
            </span>{" "}
          </Checkbox>
        </div>
      </div>
      {/* <ToastContainer /> */}
      <Modal
        //   title="Add Review"

        open={model}
        onOk={null}
        onCancel={handleClose}
        centered
        // closable={false}
        footer={null}
      >
        <div className="d-flex justify-content-center">
          {loading ? <CircularProgress /> : null}
        </div>
        <div className="d-flex justify-content-center flex-column">
          <div
            dangerouslySetInnerHTML={{ __html: app ?? "" }}
            className=""
          ></div>
          {app && (
            <p className="text-center">
              <Button
                className="text-white px-5  bg-app-color"
                onClick={agreeWithall}
              >
                Agree{" "}
              </Button>
              {/* <Button
                className="text-white px-5  bg-app-color"
                onClick={agreeWithall}
              >
               Not agree Agree{" "}
              </Button> */}
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ManageDocument;
