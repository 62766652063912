import { CircularProgress } from "@mui/material";
import { IoWalletSharp } from "@react-icons/all-files/io5/IoWalletSharp";
import { Modal, Select } from "antd";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FetchApi from "../config/ApiCal";
import "../styles/booking.css";
import Onlystripe from "./OnlyStripe";
import Paypal from "./Paypal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_CLIENT_ID } from "../config/Const";
import Gpay from "./Gpay";

function BookingModel({ action, load, type, close, Onok, data }) {
  const [inputValue, setInputValue] = useState(null);
  const [error, seterror] = useState(null);
  const booking_data = useSelector((state) => state.Currentbooking);
  const [btnLoader, setbtnLoader] = useState(false);
  const [fareEst, setfareEst] = useState(null);
  const [fareModel, setfarModel] = useState(true);
  const [paypalRes, setpaypalRes] = useState(null);
  const [stripeRes, setstripe] = useState(null);
  const [useLoader, setuseLoader] = useState(true);
  const [myData, setmyData] = useState({});
  const LoginData = useSelector((state) => state.LgnData);

  const [gpayRes, setGpayRes] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  const [stripePromise, setStripePromise] = useState(() => loadStripe(STRIPE_CLIENT_ID));

  function formatNumber(number) {
    if (Number.isInteger(number)) {
      return number.toString();
    } else {
      return number.toFixed(1);
    }
  }

  const [app, setapp] = useState(null);
  const handleChange = (event) => {
    // const value = event.target.value;
    console.log(event);
    // Check if the input value contains only dots and numbers
    // if (/^[0-9.]*$/.test(value)) {
    setInputValue(event);
    // }
  };
  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  const validate = () => {
    // console.log(inputValue.length, "hfuefh");
    if (inputValue.length > 0) {
      // Onok(in)
      setbtnLoader(true);
      const extent = FetchApi("ExtraCalFare", {
        booking_id: booking_data?.booking_id,
        hrs: inputValue,
        customer_id: LoginData?.id
      })
        .then((e) => {
          console.log(e);
          setfareEst(e.result);
          setfarModel(false);
          setbtnLoader(false);
        })
        .catch((e) => {
          setbtnLoader(false);
        });
      //   console.log(booking_data);
      //   alert("nice");
    } else {
      seterror("Enter you Hours");
    }
  };
  useEffect(() => {
    seterror(null);
    setInputValue("");
    setfarModel(true);
  }, [action]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // return () => {
    FetchApi("appsetting").then((data) => {
      const currency = data?.app_setting?.default_currency;
      // console.log(data);
      setapp({
        currency: data.currency.find((e) => e.id == currency)?.symbol,
        all: data.app_setting,
      });
    });
    // };
  }, []);

  useEffect(() => {
    if (stripeRes != null) {
      console.log("resP str", stripeRes);
      paymentDone("stripe");
    }
  }, [stripeRes]);
  useEffect(() => {
    if (paypalRes != null) {
      console.log("pwd", paypalRes);
      paymentDone("paypal");
    }
  }, [paypalRes]);

  useEffect(() => {
    if (gpayRes != null) {
      console.log("gpayRes", gpayRes);
      let type = paymentType == "apple" ? "apple" : "stripe_gpay";
      paymentDone(type);
    }
  }, [gpayRes]);

  const overLay = (e) => {
    // $("#card-save").modal({ backdrop: false });
    const element = $(`.hide_model`).css({ background: "rgba(0, 0, 0, 0.5)" });
    // console.log(element, "ele");
  };
  const paymentDone = (type) => {
    console.log("pay");
    setuseLoader(false);
    let paymentToken = type == "paypal" ? paypalRes?.paymentID : type == 'stripe' ? stripeRes?.id : type == "stripe_gpay" || "apple" ? gpayRes?.id : "0000";
    const allPost = {
      booking_id: booking_data?.booking_id,
      payment_type: type,
      stripe_token: paymentToken,
      extended_hrs: fareEst?.total_hrs,
      extended_amount: fareEst?.total_guard_amount,
      extended_vat: fareEst?.before_vat_amount,
      extended_status: 1,
    };
    FetchApi("UpdateJob", allPost)
      .then((res) => {
        if (res.message == "Request saved successfully.") {
          toast.success("Job Extend Successfully", {
            progress: 0,
          });
          setuseLoader(true);

          close("extend");
        } else {
          toast(res.message)
          setuseLoader(true);

          console.log("toast calkked");
        }
        console.log(res, "resefefs");
      })
      .catch((e) => { });
  };
  const UiDesign = () => {
    if (type == "end") {
      return (
        <>
          <img
            src={require("../asserts/end.jpg")}
            alt=""
            style={{
              height: 200,
              objectFit: "contain",
            }}
            className="img-fluid "
            srcset=""
          />
          <div className="text-center fs-5 fw-bold ">
            Are you sure want to end this Job ?
          </div>
          <div className="d-flex justify-content-center  align-items-center gap-2 mt-2 mb-3">
            <button
              className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
              onClick={() => close("end")}
            >
              Cancel
            </button>
            <button
              onClick={Onok}
              className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
            >
              OK
            </button>
          </div>
        </>
      );
    } else if (type == "extend") {
      if (fareModel) {
        return (
          <div>
            <div className="text-start fs-5 fw-bold mb-2 ">Extend Hours</div>
            <Select
              value={inputValue}
              className=" w-100 mb-2 border-danger"
              onChange={handleChange}
              defaultActiveFirstOption
              onFocus={() => seterror(null)}
            >
              {hours.map((hour, i) => (
                <Select.Option key={hour} value={hour}>
                  {hour} {i == 0 ? "Hr" : "Hrs"}
                </Select.Option>
              ))}
            </Select>
            {error && <p className="text-danger">{error}</p>}

            <div className="d-flex justify-content-center  align-items-center gap-2 mb-4">
              <button
                className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
                onClick={() => close("extent")}
              >
                Cancel
              </button>

              <button
                onClick={btnLoader ? null : validate}
                className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
              >
                {btnLoader ? (
                  <div class="spinner-border  spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "OK"
                )}
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            {" "}
            {useLoader ? (
              <>
                <div className="d-flex justify-content-between mb-2  align-items-center ">
                  <button
                    className="btn btn-dark btn-sm "
                    onClick={() => setfarModel(true)}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => close("extend")}
                  >
                    Close
                  </button>
                </div>
                <div className="payment-d p-2 rounded-2 ">
                  <p className="mt-0 mb-3 font-sm text-center  text-white">
                    Fare Summary
                  </p>
                  <div className="fee-box">
                    {/* {data.map((val) => {
          return ( */}
                    {/* <div className="fee-details">
                      <div className="fee-keys01">Insta Guard  Fare</div>
                      <div className="fee-keys02">-</div>
                      <div className="fee-keys03">
                        {app?.currency} {fareEst?.base_fare}
                      </div>
                    </div> */}



                    {/* <div className="fee-details">
                      <div className="fee-keys01"> Guard's Fare</div>
                      <div className="fee-keys02">-</div>
                      <div className="fee-keys03">
                        {app?.currency} {fareEst?.total_guard_amount}
                      </div>
                    </div> */}
                    <div className="fee-details">
                      <div className="fee-keys01">Total Hour</div>
                      <div className="fee-keys02">-</div>
                      <div className="fee-keys03">
                        {fareEst?.total_hrs}{" "}
                        {parseInt(fareEst.total_hrs ?? 1) > 1
                          ? "hours"
                          : "hour"}
                      </div>
                    </div>

                    {/* <div className="fee-details">
                      <div className="fee-keys01">
                        VAT ({app?.all?.vat_percentage}%)
                      </div>
                      <div className="fee-keys02">-</div>
                      <div className="fee-keys03">
                        {" "}
                        {app?.currency} {fareEst?.before_vat_amount}
                      </div>
                    </div> */}
                    <div className="fee-details">
                      <div className="fee-keys01"> Total </div>
                      <div className="fee-keys02">-</div>
                      <div className="fee-keys03">
                        {app?.currency} {formatNumber(fareEst?.overall_total)}
                      </div>
                    </div>
                  </div>
                  <div className="my-3 mt-5">
                    <button onClick={() => paymentDone('wallet')} disabled={!fareEst?.wallet_status} className="btn  rounded-5 mx-2 d-flex py-2 justify-content-center gap-2 align-items-center btn-info my-2" style={{ fontSize: 12, width: '95%' }}>
                      <IoWalletSharp size={25} style={{ alignSelf: 'flex-start' }} />
                      <div className="">
                        <p className="m-0 p-0 text-start" >    Pay with Wallet </p>
                        <p className="m-0 p-0" >Available Balance:  {app?.currency}{fareEst?.wallet_amount}</p>

                      </div>

                    </button>
                    <Onlystripe
                      type={"stripe"}
                      amount={fareEst?.overall_total}
                      getToken={setstripe}
                      symbol={app?.currency}
                      click={overLay}
                      overLay={false}
                    />

                    {app?.all?.paypal_status == 1 && (
                      <Paypal
                        type={"paypal"}
                        amount={fareEst?.overall_total}
                        getToken={setpaypalRes}
                      />
                    )}

                  <Elements stripe={stripePromise}>
                    <Gpay amount={fareEst?.overall_total} getToken={setGpayRes} currency={"gbp"} setPayType={setPaymentType}/>
                  </Elements>
                  
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center flex-column  ">
                <div className="d-flex justify-content-center ">
                  <CircularProgress />
                </div>
              </div>
            )}
          </div>
        );
      }
    } else if (type == "extent_view") {
      return (
        <div>
          <div className="text-center fs-5 fw-bold mb-2  ">
            Extend Hours Details
          </div>

          <div className="payment-d p-2 py-5 rounded-2">
            <div className="fee-box">
              {/* {data.map((val) => {
          return ( */}
              <div className="fee-details">
                <div className="fee-keys01">Extend Hours</div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {data?.extended_hrs}{" "}
                  {parseInt(data.extended_hrs ?? 1) > 1 ? "hours" : "hour"}
                </div>
              </div>
              <div className="fee-details">
                <div className="fee-keys01">Extend Amount</div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {app?.currency} {data?.extended_amount}
                </div>
              </div>

              <div className="fee-details">
                <div className="fee-keys01">
                  Extent VAT ({app?.all?.vat_percentage}%)
                </div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {" "}
                  {app?.currency} {data?.extended_vat}
                </div>
              </div>
              <div className="fee-details">
                <div className="fee-keys01">Grand Total </div>
                <div className="fee-keys02">-</div>
                <div className="fee-keys03">
                  {app?.currency} {data?.total_amount}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2 align-items-center gap-2 mb-4">
            <button
              className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
              onClick={() => close("extent_view")}
            >
              close
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="text-center fs-5 fw-bold mb-2 ">
            Are you sure want to cancel this job ?
          </div>
          <div className="d-flex justify-content-center  align-items-center gap-2 mt-2 mb-3">
            <button
              className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
              onClick={() => close("cancel")}
            >
              Cancel
            </button>
            <button
              onClick={Onok}
              className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
            >
              OK
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <Modal
        centered
        title={""}
        open={action}
        onOk={null}
        onCancel={null}
        className=""
        footer={null}
        closable={false}
      >
        <div className="d-flex justify-content-center flex-column  ">
          {load ? (
            <div className="d-flex justify-content-center ">
              <CircularProgress />
            </div>
          ) : (
            UiDesign()
          )}
        </div>
      </Modal>
    </div>
  );
}

export default BookingModel;
