import { IoIosRefresh } from "@react-icons/all-files/io/IoIosRefresh";
import { Tabs } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EventCalender from '../components/EventCalender';
import { Colors } from "../config/Colors";
import { strings } from "../config/Strings";
import "../styles/bookingDetails.css";
import ListComponent from "./ListComponent";
import { ToastContainer } from "react-toastify";
function BookingDetails() {
  const navigate = useNavigate();
  const initialId = useLocation();
  const [app, setapp] = useState(null);
  // console.log(initialId, 12);
  // console.log(navigator.userAgent);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const items = [
    {
      key: 1,
      label: <p className="m-0 p-0 letter-space">On Going</p>,
      children: (
        <ListComponent
          url={"getBookingList"}
          // content={"request"}
          type={"ongoing"}
          scroll={scrollToTop}
        />
      ),
    },
    {
      key: 2,
      label: (
        <p className="m-0 p-0 letter-space">
          Upcoming
          {global.upcoming_count && global.upcoming_count > 0 ? (
            <span class="badge bg-success">{global.upcoming_count}</span>
          ) : null}
        </p>
      ),
      children: (
        <ListComponent
          url={"getBookingList"}
          type={"upcoming"}
          bottom={false}
          track={false}
          cancel
          scroll={scrollToTop}
        />
      ),
    },
    {
      key: 3,
      label: <p className="m-0 p-0 letter-space">Completed </p>,
      children: (
        <ListComponent
          url={"getBookingList"}
          type={"history"}
          track={false}
          scroll={scrollToTop}

        // bottom={false}
        />
      ),
    },
    {
      key: 4,
      label: <p className="m-0 p-0 letter-space">All Jobs</p>,
      children: (
        <ListComponent
          url={"getBookingList"}
          content={"request"}
          track={false}
          bottom={false}
          type={"all"}
          scroll={scrollToTop}
        />
      ),
    },
    {
      key: 5,
      label: <p className="m-0 p-0 letter-space">Requested</p>,
      children: (
        <ListComponent
          url={"getBookingList"}
          track={false}
          bottom={false}
          type={"unassign"}
          scroll={scrollToTop}
        />
      ),
    },
    {
      key: 6,
      label: <p className="m-0 p-0 letter-space">My Calendar</p>,
      children: <EventCalender type={2} />,
    },
  ];
  return (
    <div style={{ height: "auto" }} className="my-2 mt-5">
      <div className="container ">
        <div className="d-flex my-2 justify-content-between align-items-center">
          <div className="p-1 ">
            <h2 style={{ color: Colors.baseColor }}>
              {strings.booking}{" "}
              <span style={{ color: Colors.appColor }}>{strings.request}</span>
            </h2>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
          </div>
          <div
            className="p-1 px-2 rounded-2 bg-app-color text-white d-flex align-items-center gap-2 pointer"
            onClick={() => window.location.reload()}
          >
            <IoIosRefresh className="" />
            Refresh
          </div>
        </div>

        <div className="main-table">
          <Tabs
            style={{ zIndex: 0 }}
            defaultActiveKey={initialId.state ? initialId.state : 1}
            className="bg-white p-2 rounded-2 "
            items={items}
            size="large"
          // activeKey={initialId.state ? initialId.state : 1}

          // onTabClick={(e) => console.log(e)}
          />
        </div>
      </div>
      {/* <ToastContainer /> */}

    </div>
  );
}

export default BookingDetails;
