import { legacy_createStore } from "redux";
import IGReducer from "./Reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { encryptTransform } from 'redux-persist-transform-encrypt';

const encryptor = encryptTransform({
  secretKey: "3895c3daf1b89db494abc5e2953a4c92890c826f", 
  onError: function (error) {
    console.error("Encryption error:", error);
  },
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor], 
};

const persistedReducer = persistReducer(persistConfig, IGReducer);
const store = legacy_createStore(persistedReducer);
export const persist = persistStore(store);

export default store;
