import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import FetchApi from '../config/ApiCal';


function Refund() {
    const [data, setData] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        FetchApi('pages').then((data) => {
            if (data.message == "Pages listed successfully.") {
                console.log(data)
                setData(data?.data[3].description ?? '<p>No Data Found</p>')
            }
        })

    }, [])
    return (
        <div className='container'>
            <div className='work-head'><small role='button' onClick={() => {
                navigate('/')
                window.scrollTo({ top: 0, behavior: "smooth" });
            }}>Home</small>
                <IoIosArrowForward className='arr-ico' color='#2a2f83' size={22} />
                &nbsp;<span>Refund Policy</span></div>
            <div>
                <p className='work-sub-head'>Refund <span>Policy</span></p>

                {
                    data ?

                        <div dangerouslySetInnerHTML={{ __html: data }} className='mt-4 work-sub-desc'>
                        </div>
                        :
                        null
                }

            </div>
        </div>
    )
}

export default Refund;