
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AboutUs from "./components/AboutUs";
import Booking from "./components/Booking";
import BookingDetails from "./components/BookingDetails";
import ContactUs from "./components/ContactUs";
import DeleteAccount from "./components/DeleteAccount";
import EventCalender from "./components/EventCalender";
import Footer from "./components/Footer";
import ForgotPassword from "./components/ForgotPassword";
import Header from "./components/Header";
import HireGuard from "./components/HireGuard";
import Home from "./components/Home";
import Howitworks from "./components/Howitworks";
import Loading from "./components/Loading";
import Login from "./components/Login";
import ManageDocument from "./components/ManageDocument";
import MapView from "./components/MapView";
import MemberShipTable from "./components/MemberShipTable";
import NavigatorPage from "./components/NavigatorPage";
import Notification from "./components/Notification";
import PaymentScreen from "./components/PaymentScreen";
import PlanList from "./components/PlanList";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Profile from "./components/Profile";
import Refund from "./components/Refund";
import Register from "./components/Register";
import Service from "./components/Service";
import SuccessPage from "./components/SuccessPage";
import TermsCondition from "./components/TermsCondition";
import VerifyAccount from "./components/VerifyAccount";
import ViewBooking from "./components/ViewBooking";
import Wallet from "./components/Wallet";
import store from "./Redux/Store";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
// import { images } from "./config/ConstImg";
function App() {
  const urlMethod = window.location.href.split("/")[3];
  const LoginData = useSelector((state) => state.LgnData);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const Badge = () => {
    useEffect(() => {
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/660d3049a0c6737bd127d51f/1hqho98v6';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();

      // chekAuthenticate();
    }, [])
  
    useEffect(() => {
      let isAuth = Object.entries(LoginData).length > 0 ? LoginData.id : null;
      setUser(isAuth);
      setLoading(false);
    }, [LoginData]);
        // return (
    //   <div className='bg-info fw-bold text-white letter-space p-2 display_badge' >
    //     <Fade cascade duration={500} >
    //       LONDON ONLY
    //     </Fade>
    //   </div>
    // )
    // return (
    //   <div className="whatsapp">
    //     <a target="blank" href="https://api.whatsapp.com/send?phone=+447920400283">
          // <img src={images.whatsApp} alt="whatsapp"/>
    //     </a>
    //   </div>
    // )
  }

  return (
    <BrowserRouter >
      {urlMethod == "calender" ? null : <Header />}
      <Notification />
      <Badge />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bookingDetails" element={loading ? <></> : user && user != null  ? <BookingDetails /> : <Login/>} />
        <Route path="/mapView" element={<MapView />} />
        <Route path="/viewBooking" element={loading ? <></> : user && user != null ? <ViewBooking /> : <Login/>} />
        <Route path="/services" element={<Service />} />
        <Route path="/profile" element={ loading ? <></> :  user && user != null ? <Profile /> : <Login />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/manageDocument" element={<ManageDocument />} />
        <Route path="/services/booking" element={<Booking />} />
        <Route path="/services/booking/loading" element={<Loading />} />
        <Route path="/services/booking/payment" element={<PaymentScreen />} />
        <Route path="/recruitment" element={<HireGuard />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/verifyAccount" element={<VerifyAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/howitworks" element={<Howitworks />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsConditions" element={<TermsCondition />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/refundpolicy" element={<Refund />} />
        <Route path="/subscriptionPlans" element={loading ? <></> : user && user != null ? <PlanList /> : <Login />} />
        <Route path="/delete_account" element={<DeleteAccount />} />
        <Route path="/subscriptionHistory" element={<MemberShipTable />} />
        <Route path="calender/:type/:id/:token" element={<EventCalender />} />
        <Route path="chat/:booking/:driver" element={<NavigatorPage />} />

        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {urlMethod == "calender" ? null : <Footer />}
    </BrowserRouter>
  );
}

export default App;
