// PaymentForm.js
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { toast } from "react-toastify";
import FetchApi from "../config/ApiCal";

const CheckoutForm = ({ amount, setToken, symbol }) => {
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "black",
        fontSize: "16px",
        fontFamily: "sans-serif",
        fontSmoothing: "antialiased",
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#e5424d",
        },
      },
    },
    hidePostalCode: true,
  };
  const [paymentError, setPaymentError] = useState(null);
  //   const [amount, setAmount] = useState(10);
  const [btnLoad, setBtnLoad] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  // const handleSubmit = async (event) => {
  //   setBtnLoad(true);
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardElement);

  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: cardElement,
  //   });

  //   if (error) {
  //     setBtnLoad(false);
  //     setPaymentError(error.message);
  //   } else {
  //     // console.log('PaymentMethod:', paymentMethod);
  //     let params = {
  //       payment_method_id: paymentMethod.id,
  //       amount: parseFloat(amount) * 100,
  //     };

  //     FetchApi("createPaymentIntent", params).then((data) => {
  //       console.log(data);
  //       if (data.message == "Payment Created") {
  //         setToken(data.result);
  //         toast.success("Payment Authorized Successfully", {
  //           position: "top-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         cardElement.clear();
  //         document.getElementById("card-close").click();
  //         setBtnLoad(false);
  //       } else {
  //         toast.error(data.message, {
  //           position: "top-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         setBtnLoad(false);
  //       }
  //     });
  //   }
  // };
  const handleSubmit = async (event) => {
    setPaymentError(null);
    setBtnLoad(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    let params = {
      amount: parseFloat(amount) * 100,
    };
    let clientSecret;
    let apiResponse = await FetchApi("createPaymentIntent", params);
    // console.log(apiResponse, "res")
    if (apiResponse.message == "Payment Created") {
      clientSecret = apiResponse.result.client_secret;
    } else {
      setBtnLoad(false);
      setPaymentError(apiResponse.message);
      return;
    }
    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );
    console.log(paymentIntent, "payment");
    if (error) {
      // Handle error
      setBtnLoad(false);
      setPaymentError(error.message);
    } else if (
      paymentIntent.status === "requires_action" &&
      paymentIntent.next_action.type === "use_stripe_sdk"
    ) {
      // Handle 3D Secure authentication
      const { error: authError } = await stripe.handleCardAction(clientSecret);

      if (authError) {
        setBtnLoad(false);
        toast.error(authError.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (paymentIntent.status === "requires_capture") {
        console.log(paymentIntent, "strt");
        setToken(paymentIntent);
        toast.success("Payment Authorized Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        cardElement.clear();
        document.getElementById("card-close").click();
        setBtnLoad(false);
      } else {
        setBtnLoad(false);
        toast.error("Payment declined", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (paymentIntent.status === "requires_capture") {
      setToken(paymentIntent);

      toast.success("Payment Authorized Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      cardElement.clear();
      document.getElementById("card-close").click();
      setBtnLoad(false);
    } else {
      setBtnLoad(false);
      toast.error("Payment declined", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />

      {paymentError && <div className="pay-err">{paymentError}</div>}
      <button type="submit" className="pay-card" disabled={!stripe || btnLoad}>
        {btnLoad ? (
          <small className="spinner-border spinner-border-sm"></small>
        ) : (
          <span>
            Pay &nbsp;{symbol} {amount}
          </span>
        )}{" "}
      </button>
    </form>
  );
};

export default CheckoutForm;
