import { IoMdArrowDropleftCircle } from "@react-icons/all-files/io/IoMdArrowDropleftCircle";
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FetchApi from "../config/ApiCal";
import { Colors } from '../config/Colors';
const MemberShipTable = () => {
  const LoginData = useSelector((state) => state.LgnData);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch your data here. For demonstration purposes, using dummy data.

    fetchData();
  }, [pagination]);
  const fetchData = async () => {
    setLoading(true);
    console.log(pagination, 'pahr')
    // Simulating API call delay
    FetchApi('subscription_history', {
      user_type: 1,
      cus_id: LoginData.id,
      offset: pagination.current - 1
    }).then((e) => {
      setData(e.result)
      setLoading(false);
    }).catch(e => alert(e.message));

  };
  const handleTableChange = (pagination) => {
    console.log(pagination, 'data')
    setPagination(pagination);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 'max-content',
      render: (text) => <p>{text}</p>
    },
    {
      title: 'Plan Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <p>{text}</p>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => <p>{text}</p>
    }, {
      title: 'Period',
      dataIndex: 'plan_period',
      key: 'plan_period',
      render: (text) => <p>{text}</p>
    }, {
      title: 'Paid Date',
      dataIndex: 'purchase_date',
      key: 'purchase_date',
      render: (text) => <p>{text}</p>
    }, {
      title: 'Expire Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (text) => <p>{text}</p>
    },
    {
      title: 'Auto renew',
      dataIndex: 'auto_renew',
      key: 'auto_renew',
      render: (text, record) => <p>{record.status == 1 ? text == 2 ? <button onClick={() => autoRenew(record, 1)} className={'btn btn-sm btn-success'} >Enable Auto Renew</button> : <button onClick={() => autoRenew(record, 2)} className={'btn btn-sm btn-danger'} >Disable Auto Renew</button> : '----'} </p>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => text == 1 ? <p className='text-success fw-bold'>Active</p> : <p className='text-danger fw-bold'>Expire</p>
    },
  ];
  const autoRenew = (data, record) => {
    setLoading(true)
    console.log(data, 'see')
    FetchApi('updateAutoRenew', {
      user_type: 1,
      customer_id: LoginData.id,
      subscription_id: data.id,
      auto_renew: record,
      type: 'web',
      offset: 0
    }).then(e => {
      console.log(e, 'ee')
      setLoading(false)
      fetchData()
    })
  }

  return (
    <div className='container mt-5' style={{ minHeight: '70vh', zIndex: 0 }}>
      <button
        className="text-white  rounded-2 some_btn   bg-app-color"
        onClick={() =>
          navigate(-1)
        }
      >
        <IoMdArrowDropleftCircle />
        Back
      </button>
      <h2 className='my-4' style={{ color: Colors.baseColor }}>
        Subscription
        <span style={{ color: Colors.appColor }}> History</span>
      </h2>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        style={{ zIndex: 0 }}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 'max-content', y: 'max-content' }}
      />
    </div>
  );
};

export default MemberShipTable;
