import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../Redux/Store';
import { baseUrl } from '../config/Const';
import ChatBox from './ChatBox';

function WebChat() {
    const Chat = require("twilio-chat");
    const [trigger, setTrigger] = useState(false);
    const [channel, setchannel] = useState(null);
    const [chatloader, setChatloader] = useState(false);
    const [selectDriver, setselectDriver] = useState("");
    const redux_data = useSelector((state) => state.ChatReciever);
    const LoginData = useSelector((state) => state.LgnData);
    const [conversations, setconversations] = useState([]);

    // console.log(redux_data, 'dsdsddss')
    // console.log(trigger, 'trrr');
    useEffect(() => {
        // Subscribe to the "messageAdded" event
        if (channel != null) {


            // console.log(channel.channelState,'mmm')
            channel.on("messageAdded", handleMessageAdded);

            return () => {
                channel.off("messageAdded", handleMessageAdded);
            };
        }

        // Unsubscribe when the component is unmounted
    }, [channel]);
    const sendMessage = (newMessage) => {
        if (newMessage) {
            channel.sendMessage(String(newMessage).trim());

            setTimeout(() => {
                setTrigger(!trigger);
            }, 100);

            // scrollToBottom();
        }
    };
    const setappearChat = () => {
        store.dispatch({ type: 'push_disappear', payload: '' })
        leftChannel(channel)

    }

    useEffect(() => {
        if (redux_data.open) {

            ChatWithGuard()
        }

    }, [redux_data.bookingID])

    const ChatWithGuard = async () => {
        setChatloader(true);
        // setappearChat(!appearChat);
        setselectDriver(redux_data?.driver_id);
        setconversations([]);

        var chat_identity = "customer" + redux_data?.driver_id + "InstaGuard";
        var room_identity =
            "Channel:" +
            LoginData.id +
            "," +
            redux_data?.driver_id +
            "," +
            redux_data?.bookingID;
        const response = await axios.post(
            baseUrl + "chatAccessToken?identity=" + chat_identity
        );
        const { data } = response;
        // console.log(data)
        // setToken(data.token);
        const client = await Chat.Client.create(data.token);
        // console.log(client);

        client.on("tokenAboutToExpire", async () => {
            const { token } = ChatWithGuard();
            client.updateToken(token);
            // console.log("expToken", token);
        });

        client.on("tokenExpired", async () => {
            const { token } = ChatWithGuard();
            client.updateToken(token);
            // console.log("expToken", token);
        });

        client.on("channelJoined", async (channel) => {
            const messages = await channel.getMessages();
            const findChanel = await channel.uniqueName;
            console.table(messages.items.map(e => e.body), 'nsns')
            if (findChanel == room_identity) {
                setconversations(messages.items || []);
            }
            // console.log(await channel.uniqueName)

            // scrollToBottom();
            setTrigger(!trigger);
        });

        try {
            const channel = await client.getChannelByUniqueName(room_identity);
            setchannel(channel);
            joinChannel(channel);
        } catch (err) {
            try {
                const channel = await client.createChannel({
                    uniqueName: room_identity,
                    friendlyName: room_identity,
                });

                joinChannel(channel);
                setchannel(channel);
            } catch {
                throw new Error("Unable to create channel, please reload this page");
            }
        }
        setChatloader(false);

        return data;
    };
    const handleMessageAdded = (message) => {


        setconversations((pre) => [...pre, message]);
        setTrigger((pre) => !pre)
    };
    const joinChannel = async (channel) => {

        if (channel.channelState.status !== "joined") {
            await channel.join();
        }
    };
    const leftChannel = async (channel) => {

        // if (channel.channelState.status !== "joined") {
        await channel.leave();
        // }
    };
    return (
        <div>

            <ChatBox
                action={redux_data.open}
                trigger={trigger}
                sendMsg={sendMessage}
                message={conversations}
                setAction={setappearChat}
                loader={chatloader}
                driver_id={selectDriver}
            />
        </div>
    )
}

export default WebChat