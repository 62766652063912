import React from "react";
import { useNavigate } from "react-router-dom";
import { stingsTxt } from "../config/Const";
import { images } from "../config/ConstImg";
import "../styles/AboutUs.css";
const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="about-us-play-ground container">
      <div className="about-us-box">
        <span className="home-service-txt about-us-hd-txt">
          {stingsTxt.about} <span>{stingsTxt.us}</span>
        </span>
        <span className="about-us-box-desc">
          UK’s First Automated Residential Security Service .
        </span>
        <div className="about-us-box-play bg-white rounded-3 mt-5">
          <div className="about-us-box-play-box01">
            <div className="">
              <span className="home-service-txt about-us-box01-left">
                What is <span>Insta Guard?</span>
              </span>
              <br />
              <span className="about-us-box-play-box01-left-desc">
                Insta-Guard is the first of its kind in the security industry,
                which was commenced in 2023 during lockdown. The fully automated
                security platform originated from a deputy head teacher, a
                former police constable & a retired solicitor who formed an idea
                and a blueprint to help people, businesses, and residents who
                want a heightened security presence.
              </span>
            </div>
            <img
              src={images.aboutImg01}
              className="about-us-box-play-box03-right"
            />
          </div>

          {/* <div className="about-us-box-play-box02">
            <div className="about-us-box-play-box01-left">
              <span className="home-service-txt about-us-box01-left">
                What do <span>we do?</span>
              </span>
              <span className="about-us-box-play-box01-left-desc">
                Insta-guard lets you hire close protection security who are
                close to your proximity. As the service grows, we plan on
                featuring more services and having our enforcement teams patrol
                24/7 in all boroughs across London.
              </span>
            </div>
            <img
              src={images.aboutImg02}
              className="about-us-box-play-box03-right"
            />
          </div> */}

          <div className="about-us-box-play-box01 my-3">
            <div className="about-us-box-play-box01-left">
              <span className="home-service-txt about-us-box01-left">
                What is <span>our Aim?</span>
              </span>
              <span className="about-us-box-play-box01-left-desc m-0" >
                This breakdown effectively encapsulates Insta-guard's multifaceted aim and its commitment to benefiting both its customers and the broader community. Here's a summary of the key points:
              </span>
              <ul className='list-style-none ul-lister'>
                <li>
                  <strong>Customer Benefit:</strong>  <br /> Insta-guard prioritises providing peace of mind and added security to its customers through on-demand physical security services. Leveraging technology and a network of security professionals, the platform ensures rapid response times during emergencies, ultimately enhancing user safety and well-being.
                </li>
                <li>
                  <strong>Support for Police Forces:</strong> <br /> Insta-guard recognises the challenges faced by overworked and underpaid police forces in London. By supplementing law enforcement efforts, Insta-guard aims to alleviate some of the burdens on police resources, enabling them to focus on critical tasks while still ensuring swift and effective responses to security incidents.
                </li>
                <li>
                  <strong>Community Impact:</strong>  <br />  Beyond its direct users and law enforcement, Insta-guard's efforts extend to benefiting the broader community. By enhancing public safety and security, the platform contributes to creating safer neighbourhoods and environments for everyone, fostering a sense of security and well-being.
                </li>
                <li>
                  <strong>Collaborative Approach:</strong>  <br /> Insta-guard acknowledges the importance of collaboration between private security services and public law enforcement agencies. By working together, Insta-guard aims to create synergies that improve.
                </li>
                <li>
                  <strong>Overall security outcomes:</strong> <br />  benefiting society as a whole and promoting a safer and more secure environment for all.
                </li>
                <li>Overall, Insta-guard's commitment to innovation, collaboration, and community impact underscores its dedication to making a meaningful difference in the security landscape of London and beyond. Through its multifaceted approach, Insta-guard aims to contribute to a safer and more secure society while supporting both its customers and law enforcement partners.</li>
              </ul>
            </div>
            {/* <img
              src={images.aboutImg05}
              className="about-us-box-play-box03-right"
              style={{ borderRadius: "20px" }}
            /> */}
          </div>

          <div className="about-us-box-play-box03">
            {/* <img
              src={images.aboutImg05}
              className="about-us-box-play-box03-right rounded"
            /> */}
            <img
              src={images.aboutImg04}
              className="about-us-box-play-box03-right"
              style={{maxHeight:'none'}}
            />

          </div>


        </div>
      </div>
    </div>
  );
};

export default AboutUs;
