import LocalizedStrings from "react-localization";

export let strings = new LocalizedStrings({
  en: {
    choice: "How to choose the egg",
    homeHeadText: "Are your refuge & strength an ever-present help when you’re in trouble.",
    here_are_the_directions_to_use_Insta_Guard: "Here are the directions to use Insta Guard:",
    any_time: "Anytime / Anyplace / Anywhere Just Insta-guard it!",
    create_an_acconunt: 'Create an acconunt',
    choose_a_service: 'Choose a service',
    book_a_consultation_with_an_expert: 'Book a consultation with an expert',
    pay_for_your_order: 'Pay for your order',
    delivery_of_security_service: 'Delivery of security service',
    we_only_use: 'We only use Close Protection Professionals, Ex-Police & Higlhy ranked Militry Officers',
    book_a_guard: 'BOOK A GUARD',
    booking: "Booking",
    request: "Request",
  },
  it: {
    how: "Come vuoi il tuo uovo oggi?",
    boiledEgg: "Uovo sodo",
    softBoiledEgg: "Uovo alla coque",
    choice: "Come scegliere l'uovo",
  },
});
