import React from 'react'

function DeleteAccount() {
    return (
        <div className="container  bg-white rounded-2 mt-5 p-2 " style={{ minHeight: '70vh', width: '100vw', }}>


            <div style={{ maxWidth: '600px', margin: 'auto' }}>
                <div className="header text-center mb-5">
                    <h1>Insta-Guard - Request Account Deletion</h1>
                    <p>
                        If you wish to delete your account, please follow the instructions
                        below:
                    </p>
                </div>
                <div className="instructions mb-5 " >
                    <p>
                        To initiate the account deletion process, send an email to{" "}
                        <a href="mailto:info@insta-guard.co.uk" className="text-decoration-none">
                            info@insta-guard.co.uk
                        </a>{" "}
                        with the subject line: "Account Deletion Request."
                    </p>
                    <p>
                        Once we receive your email, our team will process your request and
                        delete your account.
                    </p>
                    <p>
                        If you have any questions or need further assistance, feel free to
                        contact us at{" "}
                        <a href="mailto:info@insta-guard.co.uk" className="text-decoration-none">
                            info@insta-guard.co.uk
                        </a>
                        .
                    </p>
                </div>
                <div className="privacy-policy mt-5 pt-5 border-top ">
                    <p>
                        For more information about how we handle your data and our privacy
                        practices, please review our{" "}
                        <a
                            href="http://www.insta-guard.co.uk/privacypolicy"
                            className="privacy-link"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount