import { ListItem } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
function ChatItem({ message,email,key }) {
  const isOwnMessage = message.author == email;
  // console.log(message.author,'dsdfsy')
  // console.log(key,'wmil')

  return (
    <div>
      {" "}
      <ListItem style={styles.listItem(isOwnMessage)} key={key}>
        <div style={styles.author}>{isOwnMessage ? 'You':'Guard'}</div>
        <div style={styles.container(isOwnMessage)}>
          {message.body}
          <div style={styles.timestamp}>{dayjs(message.dateCreated).format('MM/DD/YYYY  hh:mm a')}</div>
        </div>
      </ListItem>
    </div>
  );
}
const styles = {
  listItem: (isOwnMessage) => ({
    flexDirection: "column",
    alignItems: isOwnMessage ? "flex-end" : "flex-start",
  }),
  container: (isOwnMessage) => ({
    maxWidth: "75%",
    borderRadius: 12,
    padding: 15,
    color: "white",
    fontSize: 14,
    backgroundColor: isOwnMessage ? "#832b67" : "#2a2f83",
  }),
  author: { fontSize: 10, color: "gray" },
  timestamp: { fontSize: 8, color: "white", textAlign: "right", paddingTop: 4 },
};

export default ChatItem;
