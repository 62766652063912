import { Backdrop, CircularProgress } from "@mui/material";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { RiEyeOffLine } from "@react-icons/all-files/ri/RiEyeOffLine";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialLinkedin } from "reactjs-social-login";
import store from "../Redux/Store";
import FetchApi from "../config/ApiCal";
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, LINKEDIN_CLIENT_ID, LINKEDIN_CLIENT_SECRET, LINKEDIN_REDIRECT_URL } from "../config/Const";
import { LoginString } from "../config/LoginString";
import "../styles/Login.css";
import { useSelector } from "react-redux";

const Login = () => {
    const [backdropLoader, setBackdropLoader] = useState(false);
    const [type, setType] = useState("password");
    const navigate = useNavigate();
    const [igbtnLoad, setIgBtnLoad] = useState(false);
    const [igEmail, setIGEmail] = useState("");
    const [igPass, setIGPass] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [passErr, setPassErr] = useState("");
    const [provider, setProvider] = useState("");
    const [profile, setProfile] = useState(null);
    const [googleButton, setGoogleButton] = useState(false);
    const LoginData = useSelector((state) => state.LgnData);

    useEffect(() => {
        // let isAuth = localStorage.getItem("user_id");
        let isAuth = Object.entries(LoginData).length > 0 ? LoginData.id : null;

        if (isAuth != null) {
            navigate("/");
        }
    }, []);

    const handleShowHide = (eyechange) => {
        eyechange === "password" ? setType("password") : setType("text");
    };
    //login function
    const IGLogin = async (e) => {
        e.preventDefault();
        setIgBtnLoad(true);

        if (loginValidate()) {
            const lgparams = {
                email: igEmail,
                password: igPass,
            };

            //api call
            const ApiResponse = await FetchApi("auth/login", lgparams);
            console.log(ApiResponse, 'redd')
            if (ApiResponse.message == "loggedin successfully") {
                //this store the data in redux store
                const loginData = {
                    id: ApiResponse.result.customer.id,
                    first_name: ApiResponse.result.customer.first_name,
                    last_name: ApiResponse.result.customer.last_name,
                    avatar: ApiResponse.result.customer.avatar,
                    email: ApiResponse.result.customer.email,
                    country_code: ApiResponse.result.customer.country_code,
                    phone_number: ApiResponse.result.customer.phone_number,
                    wallet_balance: ApiResponse.result.customer.wallet_balance,
                    password: ApiResponse.result.customer.password,

                };

                store.dispatch({ type: "addUserData", payload: loginData });
                // localStorage.setItem("user_id", ApiResponse.result.customer.id);

                toast.success("Login Successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                setTimeout(() => {
                    // navigate("/");
                    setIGEmail("");
                    setIGPass("");
                    setIgBtnLoad(false);
                    navigate("/");
                    window.scrollTo({ top: 0, behavior: "smooth" });

                }, 1000);
            } else {
                toast.error(ApiResponse.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIgBtnLoad(false);
            }
        } else {
            setIgBtnLoad(false);
        }
    };

    const loginValidate = () => {
        let isValid = true;

        if (igEmail == "") {
            setEmailErr("Please Enter your email");
            isValid = false;
        } else {
            setEmailErr("");
        }
        if (igEmail != "") {
            let lastAtPos = igEmail.lastIndexOf("@");
            let lastDotPos = igEmail.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    igEmail.indexOf("@@") == -1 &&
                    lastDotPos > 2
                )
            ) {
                isValid = false;
                setEmailErr("Please Enter valid Email");
            }
        }

        if (igPass == "") {
            setPassErr("Please Enter password  ");
            isValid = false;
        } else {
            setPassErr("");
        }
        if (igPass.length < 8) {
            setPassErr("password must min 8 character");
            isValid = false;
        } else {
            setPassErr("");

        }
        return isValid;
    };

    //social login

    const LoginWithSocial = async (provider, data) => {
        const params = {
            social_id: data.id,
            social_type: "facebook",
            device_id: 123456,
        };

        const ApiResponse = await FetchApi("customer/socialAdd", params);
        // console.log("ApiResponse --> ", ApiResponse);
        if (ApiResponse.msg == "Customer login successful.") {
            const updateparams = {
                device_id: 123456,
                email: data.email,
                customer_id: ApiResponse.id,
                first_name: data.first_name,
                last_name: data.last_name,
            };
            const FaceBookloginData = {
                id: ApiResponse.result.id,
                first_name: ApiResponse.result.first_name,
                last_name: ApiResponse.result.last_name,
                avatar: ApiResponse.result.avatar,
                email: ApiResponse.result.email,
                country_code: ApiResponse.result.country_code,
                phone_number: ApiResponse.result.phone_number,
                wallet_balance: ApiResponse.result.wallet_balance,
            };
            if (ApiResponse.result.email != "") {
                setBackdropLoader(false);
                store.dispatch({ type: "addUserData", payload: FaceBookloginData });
                // localStorage.setItem("user_id", ApiResponse.result.id);
                navigate("/");
            } else {
                setBackdropLoader(false);
                store.dispatch({ type: "addSocialLoginData", payload: updateparams });
                navigate("/register");
            }
        } else if (ApiResponse.msg == "New Registration") {
            const updateparams = {
                device_id: 123456,
                email: data.email,
                customer_id: ApiResponse.id,
                first_name: data.first_name,
                last_name: data.last_name,
            };
            setBackdropLoader(false);
            store.dispatch({ type: "addSocialLoginData", payload: updateparams });
            navigate("/register");
        }
    };

    const IGLoginWithGoogle = async (data) => {
        //data.given_name
        //data.family_name
        //data.email
        const GoogleParams = { social_id: data.sub, social_type: "google", device_id: "123456" }
        const ApiResponseGoogle = await FetchApi("customer/socialAdd", GoogleParams);
        if (ApiResponseGoogle.msg == "Customer login successful.") {
            const Ggleupdateparams = {
                device_id: 123456,
                email: data.email,
                customer_id: ApiResponseGoogle.id,
                first_name: data.given_name,
                last_name: data.family_name,
            };
            const GoogleloginData = {
                id: ApiResponseGoogle.result.id,
                first_name: ApiResponseGoogle.result.first_name,
                last_name: ApiResponseGoogle.result.last_name,
                avatar: ApiResponseGoogle.result.avatar,
                email: ApiResponseGoogle.result.email,
                country_code: ApiResponseGoogle.result.country_code,
                phone_number: ApiResponseGoogle.result.phone_number,
                wallet_balance: ApiResponseGoogle.result.wallet_balance,
            };
            if (ApiResponseGoogle.result.email != "") {
                setBackdropLoader(false);
                store.dispatch({ type: "addUserData", payload: GoogleloginData });
                // localStorage.setItem("user_id", ApiResponseGoogle.result.id);
                console.log("Go", ApiResponseGoogle);
                navigate("/");
                window.scrollTo({ top: 0, behavior: "smooth" });
            } else {
                setBackdropLoader(false);
                store.dispatch({ type: "addSocialLoginData", payload: Ggleupdateparams });
                navigate("/register");
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        }
        else if (ApiResponseGoogle.msg == "New Registration") {
            const Ggleupdateparams = {
                device_id: 123456,
                email: data.email,
                customer_id: ApiResponseGoogle.id,
                first_name: data.given_name,
                last_name: data.family_name,
            };
            setBackdropLoader(false);
            store.dispatch({ type: "addSocialLoginData", payload: Ggleupdateparams });
            navigate("/register");
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }
    //

    const LoginWithLinkedIn = async (data) => {
        let params = {
            social_id: data.access_token,
            social_type: "linkden",
            web_login_link: "web_linkden",
            device_id: "123"
        }

        const ApiResponse = await FetchApi("customer/socialAdd", params);
        // console.log("ApiResponse --> ", ApiResponse);
        if (ApiResponse.msg == "Customer login successful.") {
            console.log(ApiResponse)
            const updateparams = {
                device_id: 123456,
                email: ApiResponse.linkden_result.email,
                customer_id: ApiResponse.id,
                first_name: ApiResponse.linkden_result.given_name,
                last_name: ApiResponse.linkden_result.family_name,
            };
            const LinkedinloginData = {
                id: ApiResponse.result.id,
                first_name: ApiResponse.result.first_name,
                last_name: ApiResponse.result.last_name,
                avatar: ApiResponse.result.avatar,
                email: ApiResponse.result.email,
                country_code: ApiResponse.result.country_code,
                phone_number: ApiResponse.result.phone_number,
                wallet_balance: ApiResponse.result.wallet_balance,
            };
            if (ApiResponse.result.email != "") {
                toast.success("Login Successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setBackdropLoader(false);
                store.dispatch({ type: "addUserData", payload: LinkedinloginData });
                // localStorage.setItem("user_id", ApiResponse.result.id);
                navigate("/");
                window.scrollTo({ top: 0, behavior: "smooth" });
            } else {
                store.dispatch({ type: "addSocialLoginData", payload: updateparams });
                toast.success("Enter Your Details to login", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setBackdropLoader(false);
                navigate("/register");
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        } else if (ApiResponse.msg == "New Registration") {
            const updateparams = {
                device_id: 123456,
                email: ApiResponse.linkden_result.email,
                customer_id: ApiResponse.id,
                first_name: ApiResponse.linkden_result.given_name,
                last_name: ApiResponse.linkden_result.family_name,
            };
            store.dispatch({ type: "addSocialLoginData", payload: updateparams });
            toast.success("Enter Your Details to login", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBackdropLoader(false);
            navigate("/register");
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        else {
            toast.error("Login failed try again", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBackdropLoader(false);
        }
    }

    return (
        <div className="Login-Section">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropLoader}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
            <div className="d-flex  justify-content-center">
                <div className="Login-title-txt">
                    <span className="Login-frtext">{LoginString.LoginTitle}</span>{" "}
                    <span className="Login-sectext">{LoginString.LoginGetText}</span>
                    <div>
                        <span className="subtitle-text">{LoginString.LoginSubTitle}</span>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <div className="row d-flex text-align-center justify-content-center">
                    <div className="col-lg-5">
                        <div className="LoginBox">
                            {/* <div className='container'>
                            <div className='row align-items-center text-center'>
                                <div className='col-6 pt-2 pb-2'>
                                   <span className='email-text'>{LoginString.LoginEmailText}</span>
                                </div>
                                <div className='col-6 pt-2 pb-2'>
                                  <span className='email-text'>{LoginString.LoginMobileText}</span>
                                </div>
                            </div>
                         </div> */}
                            <div className="Input-Box-Section pt-3 pb-2">
                                <div className="lable-section">
                                    <form>
                                        <div className={emailErr ? "form-group grp-2" : "form-group grp-1"}>
                                            <label for="exampleInputEmail1" className="form-labels">
                                                {LoginString.Label1}
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter email"
                                                onChange={(e) => setIGEmail(e.target.value)}
                                                onFocus={() => setEmailErr("")}
                                                value={igEmail}
                                            />
                                            <span className="Errors">{emailErr ? emailErr : ""}</span>
                                        </div>
                                        <div className={passErr ? "form-group grp-2" : "form-group grp-1"} id="passwordInput">
                                            <label
                                                for="exampleInputPassword1"
                                                className="form-labels"
                                            >
                                                {LoginString.Label2}
                                            </label>
                                            <span className="eyevector">
                                                {type === "password" ? (
                                                    <RiEyeOffLine
                                                        style={{ fontSize: "25px" }}
                                                        onClick={() => handleShowHide("text")}
                                                    />
                                                ) : (
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        onClick={() => handleShowHide("password")}
                                                    >
                                                        <path
                                                            d="M11.9999 16.3299C9.60992 16.3299 7.66992 14.3899 7.66992 11.9999C7.66992 9.60992 9.60992 7.66992 11.9999 7.66992C14.3899 7.66992 16.3299 9.60992 16.3299 11.9999C16.3299 14.3899 14.3899 16.3299 11.9999 16.3299ZM11.9999 9.16992C10.4399 9.16992 9.16992 10.4399 9.16992 11.9999C9.16992 13.5599 10.4399 14.8299 11.9999 14.8299C13.5599 14.8299 14.8299 13.5599 14.8299 11.9999C14.8299 10.4399 13.5599 9.16992 11.9999 9.16992Z"
                                                            fill="#042E60"
                                                        />
                                                        <path
                                                            d="M2.67146 14.7308L2.67075 14.7297C2.2037 14.0027 1.95508 13.0171 1.95508 12.0037C1.95508 10.9907 2.20356 10.0021 2.67121 9.26951C5.05218 5.55738 8.45904 3.47998 12.0001 3.47998C15.5413 3.47998 18.948 5.55756 21.3187 9.26913L21.3194 9.27023C21.7865 9.99724 22.0351 10.9828 22.0351 11.9962C22.0351 13.0095 21.7865 13.9982 21.3187 14.7309C18.9479 18.4424 15.5413 20.52 12.0001 20.52C8.44863 20.52 5.04205 18.4422 2.67146 14.7308ZM12.0001 3.97998C8.55682 3.97998 5.32506 6.04702 3.09877 9.54072C2.65861 10.2277 2.45758 11.1313 2.45758 12C2.45758 12.8684 2.6585 13.7718 3.0984 14.4587C5.3247 17.9527 8.55663 20.02 12.0001 20.02C15.4433 20.02 18.6751 17.9529 20.9014 14.4592C21.3415 13.7723 21.5426 12.8686 21.5426 12C21.5426 11.1312 21.3415 10.2274 20.9012 9.54039C18.6749 6.04689 15.4432 3.97998 12.0001 3.97998Z"
                                                            fill="#042E60"
                                                            stroke="#042E60"
                                                        />
                                                    </svg>
                                                )}
                                            </span>
                                            <input
                                                type={type}
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Password"
                                                onChange={(e) => setIGPass(e.target.value)}
                                                onFocus={() => setPassErr("")}
                                                min={8}
                                                value={igPass}
                                            />
                                            <span className="Errors">{passErr ? passErr : ""}</span>
                                        </div>

                                        <div className="Login-Buttons pt-5">
                                            <div className="d-flex flex-column-reverse flex-lg-row flex-md-row justify-content-between align-items-center">
                                                <span
                                                    className="fpwrd"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => navigate("/forgotPassword")}
                                                >
                                                    {LoginString.LoginFP}
                                                </span>
                                                <button
                                                    type="button"
                                                    className="lgn-btn"
                                                    onClick={IGLogin}
                                                    disabled={igbtnLoad}
                                                >
                                                    {igbtnLoad ? (
                                                        <small className="spinner-border spinner-border-sm"></small>
                                                    ) : (
                                                        LoginString.LoginBtn
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <span className="divider-or d-flex justify-content-center">
                                    Or
                                </span>

                                <div className="social-login d-flex  justify-content-center gap-4 pt-4">
                                    <LoginSocialFacebook
                                        appId={FACEBOOK_APP_ID}
                                        fieldsProfile={"id,first_name,last_name,email,name"}
                                        onResolve={({ provider, data }) => {
                                            setBackdropLoader(true)
                                            LoginWithSocial(provider, data);
                                        }}
                                        onReject={(err) => {
                                            console.log(err);
                                        }}
                                    >
                                        <svg
                                            width="25"
                                            height="24"
                                            viewBox="0 0 25 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <g clip-path="url(#clip0_1_1418)">
                                                <path
                                                    d="M14.5 13.5H17L18 9.5H14.5V7.5C14.5 6.47 14.5 5.5 16.5 5.5H18V2.14C17.674 2.097 16.443 2 15.143 2C12.428 2 10.5 3.657 10.5 6.7V9.5H7.5V13.5H10.5V22H14.5V13.5Z"
                                                    fill="#1D62EB"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_1418">
                                                    <rect
                                                        width="24"
                                                        height="24"
                                                        fill="white"
                                                        transform="translate(0.5)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </LoginSocialFacebook>
                                    <LoginSocialGoogle client_id={GOOGLE_CLIENT_ID}
                                        scope="email https://www.googleapis.com/auth/userinfo.profile"
                                        discoveryDocs="claims_supported"
                                        access_type="online"
                                        onResolve={({ data }) => {
                                            // console.log(data);
                                            setBackdropLoader(true)
                                            IGLoginWithGoogle(data);
                                        }
                                        }
                                        onReject={err => {
                                            console.log(err);
                                            toast.error("Login failed try again", {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            });
                                        }}>

                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} disabled>
                                            <path d="M5.87567 14.2951L5.10988 17.1539L2.31093 17.2131C1.47446 15.6616 1 13.8865 1 12.0002C1 10.1761 1.44361 8.45601 2.22994 6.94141H2.23054L4.72238 7.39825L5.81396 9.87514C5.5855 10.5412 5.46097 11.2562 5.46097 12.0002C5.46106 12.8077 5.60733 13.5813 5.87567 14.2951Z" fill="#FBBB00" />
                                            <path d="M22.8082 9.94482C22.9345 10.6102 23.0004 11.2974 23.0004 11.9998C23.0004 12.7873 22.9176 13.5555 22.7598 14.2965C22.2243 16.818 20.8252 19.0198 18.8869 20.578L18.8863 20.5774L15.7477 20.4172L15.3035 17.6442C16.5896 16.89 17.5947 15.7096 18.1242 14.2965H12.2422V9.94482H18.21H22.8082Z" fill="#518EF8" />
                                            <path d="M18.8855 20.5776L18.8861 20.5782C17.001 22.0934 14.6064 23 11.9996 23C7.81055 23 4.16847 20.6586 2.31055 17.2129L5.87528 14.2949C6.80422 16.7741 9.19582 18.539 11.9996 18.539C13.2048 18.539 14.3338 18.2132 15.3026 17.6445L18.8855 20.5776Z" fill="#28B446" />
                                            <path d="M19.0218 3.53241L15.4583 6.44981C14.4556 5.82307 13.2703 5.46102 12.0005 5.46102C9.13327 5.46102 6.69694 7.30683 5.81454 9.87494L2.23107 6.94121H2.23047C4.0612 3.41154 7.7492 1 12.0005 1C14.6695 1 17.1167 1.95073 19.0218 3.53241Z" fill="#F14336" />
                                        </svg>
                                    </LoginSocialGoogle>
                                    <LoginSocialLinkedin
                                        client_id={LINKEDIN_CLIENT_ID}
                                        client_secret={LINKEDIN_CLIENT_SECRET}
                                        redirect_uri={LINKEDIN_REDIRECT_URL}
                                        scope="email,profile,openid"
                                        onResolve={({ provider, data }) => {
                                            if (data.access_token) {
                                                setBackdropLoader(true)
                                                LoginWithLinkedIn(data)
                                            }
                                            else {
                                                toast.error("Login failed try again", {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "light",
                                                });
                                            }
                                        }}
                                        onReject={(err) => {
                                            console.log(err, "errr");
                                            toast.error("Login failed try again", {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            });
                                        }}
                                    >
                                        <FaLinkedin role='button' size={22} color='#0A66C2' />
                                    </LoginSocialLinkedin>
                                </div>

                                <div className="pt-4 pb-5 text-center">
                                    <span className="dont-have-accnt">
                                        {LoginString.LoginDontAccnt}
                                    </span>{" "}
                                    <span
                                        className="rgternow"
                                        onClick={() => navigate("/register")}
                                    >
                                        {LoginString.Register}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer
                position="top-right"
                autoClose={3000}
                limit={1}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            /> */}
        </div>
    );
};

export default Login;
