import { Loader } from "@googlemaps/js-api-loader";
import React from "react";
import { images } from "../images";
function ContactMap() {

  const loader = new Loader({
    apiKey: "AIzaSyBxwzbehjrT3hVubyS9VWBPBTvglagBDv0",
    zoom: 11,
  });

  loader.load().then(async () => {
    const { Map } = await window.google.maps.importLibrary("maps");

    const map = new Map(document.getElementById("map"), {
      center: { lat: 51.50502481147013, lng: -0.10984932703861999 },
      zoom: 12,
    });

    // var iconBase = "https://maps.google.com/mapfiles/kml/shapes/";
    var marker = new window.google.maps.Marker({
      position: { lat: 51.50502481147013, lng: -0.10984932703861999 },
      map: map,
      icon: images.marker,
    });
  });

  return (
    <div className="container mt-5">
      <div
        id="map"
        className="mt-3"
        style={{ width: "100%", height: "80svh", borderRadius: "10px" }}
      ></div>
    </div>
  );
}

export default React.memo(ContactMap);
