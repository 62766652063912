import { CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AiFillPhone } from "@react-icons/all-files/ai/AiFillPhone";
import { AiOutlineMessage } from "@react-icons/all-files/ai/AiOutlineMessage";
import { HiClock } from "@react-icons/all-files/hi/HiClock";
import { IoIosArrowDropdown } from "@react-icons/all-files/io/IoIosArrowDropdown";
import { IoMdArrowDropleftCircle } from "@react-icons/all-files/io/IoMdArrowDropleftCircle";
import { MdCancel } from "@react-icons/all-files/md/MdCancel";
import { MdDone } from "@react-icons/all-files/md/MdDone";
import { Input, Modal, Rate, Steps } from "antd";
import axios from "axios";
import { get, onValue, ref } from "firebase/database";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DefaultImage from "../asserts/defaultpic.jpg";
import FetchApi from "../config/ApiCal";
import { baseUrl, getStatusInfo, imageUrl } from "../config/Const";
import { firebase } from "../config/Firebase";
import { images } from "../images";
import "../styles/booking.css";
import "../styles/bookingDetails.css";
import BookingModel from "./BookingModel";
import ChatBox from "./ChatBox";
import TrackMap from "./TrackMap";
import TwilloCall from "./TwilloCall";
const Device = require("twilio-client").Device;
function MapView() {
  const booking_data = useSelector((state) => state.Currentbooking);
  // console.log(booking_data, "clg");

  const [selectDriver, setselectDriver] = useState("");
  const [expanded, setExpanded] = useState({});
  const [drivers, setdrivers] = useState([]);
  const [appearChat, setappearChat] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };
  const Chat = require("twilio-chat");
  // const { Device } = require("twilio-client").Device;
  const [reviewModel, setreviewModel] = useState(false);
  const [conversations, setconversations] = useState([]);
  const [channel, setchannel] = useState(null);
  const [chatloader, setChatloader] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [callModel, setcallModel] = useState(false);
  const [callLoader, setCallloader] = useState(false);
  const navigate = useNavigate();
  const [callStatus, setCallStatus] = useState("");

  const LoginData = useSelector((state) => state.LgnData);
  const driverLocation = ref(
    firebase,
    "booking_trips/" + booking_data?.booking_id
  );

  const [state, setstate] = useState([]);
  const [customerstate, setcustomerState] = useState({lat: 55.00201328947458,  lng: -2.66201068019162});
  
  const [ui, setui] = useState(false);
  const [show, setshow] = useState(false);
  const [showcancel, setshowcancel] = useState(false);
  const [extend, setExtend] = useState(null);
  const [isExtend, setisExtend] = useState(false);
  const [feedback, setfeedback] = useState([]);
  const [loader, setloader] = useState(false);
  const [Guard, setGuard] = useState({});
  const [time, setTime] = useState(0);
  let timer;
  // Function to start the timer
  const startTimer = () => {
    timer = setInterval(() => {
      setTime((prevTime) => prevTime + 1); // Increment the time by one second
    }, 1000);
  };

  // Function to stop the timer
  const stopTimer = () => {
    clearInterval(timer); // Clear the interval
  };

  var chat_identity = "InstaGuard_user" + LoginData?.id;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    listen();
    startListen();

    setInterval(async () => {
      const dataHolder = await get(driverLocation);
      const driver_max = Object.keys(dataHolder.val()?.drivers ?? {});
      // console.log(driver_max, "macc");
      const tripDriver = guardLoaction(driver_max).then((res) => {
        // console.log(res,'master');
        setstate(res);

      });

    }, 10000);

  }, []);

  const guardLoaction = async (ids) => {
    let ongoing = [];

    // Create an array of promises
    const promises = ids.map(async (element) => {
      const locateAll = ref(firebase, "/drivers_location/" + element);
      const driverData = ref(firebase, "/providers/" + element);
      const snap = await get(locateAll);
      const getDriverDetails = await get(driverData);
      // console.log(getDriverDetails.val(),'drr4r')
      const newlocate = snap.val();
      // Push the data into the ongoing array
      ongoing.push({
        lat: parseFloat(newlocate?.l[0]),
        lng: parseFloat(newlocate?.l[1]),
        fname: await getDriverDetails.val()?.fname,
        profile: await getDriverDetails.val()?.pro_pic,

      });
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    return ongoing;
  };

  const startListen = () => {
    get(driverLocation).then((snap) => {
      const all_value = snap.val();
      setcustomerState({
        lat: parseFloat(all_value.customer_lat),
        lng: parseFloat(all_value.customer_lon),
      });
    });
    onValue(driverLocation, (snap) => {
      // console.log(snap.val()?.extended_status, "stauts");
      const reviewFor = Object.values(snap.val().drivers ?? {}).map((e) => {
        return {
          ...e,
          to_id: e.driver_id,
          feedback: "",
          id: e.driver_id
        };
      });
      setdrivers(Object.values(snap.val().drivers ?? {}));
      if (feedback.length === 0) {
        setfeedback(reviewFor);
      }
      if (snap.exists() && snap.val().service_status === 6) {
        setshow(true);
      } else {
        setshow(false);
      }
      if (snap.exists() && snap.val().service_status <= 4 || snap.val().service_status == 13) {
        setshowcancel(true);
      } else {
        setshowcancel(false);
      }
      if (snap.exists() && snap.val().extended_status === 1) {
        setisExtend(true);
        setExtend(snap.val());
      }
    });
  };
  const handleCancelTrip = async () => {
    setloader(true);
    await FetchApi("cancelJobs", {
      booking_id: booking_data?.booking_id,
      mode: "customer",
    })
      .then((e) => {
        // console.log(e, "res");
        if (e.message == "Cancelled successfully.") {
          toast.success("Booking Cancelled successfully");
        }
        setTimeout(() => {
          navigate("/services");
        }, 3000);
      })
      .catch((e) => toast.error(e));

    setloader(false);
    setIsModalOpen(false);

    // console.log(apiReqest, "aoo");
  };

  const cus_img = LoginData.avatar != null ? imageUrl + LoginData.avatar : images.user;
  const driver_img =
    // booking_data?.driver_avatar != ""
    //   ? imageUrl + booking_data?.driver_avatar
    DefaultImage;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (ui) => {
    setui(ui);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setloader(true);
    await FetchApi("requestService", {
      booking_id: booking_data?.booking_id,
      driver_id: booking_data?.driver_id,
      mode: "payment_success",
    })
      .then((e) => {
        if (e.message == "Request status updated successfully.") {
          // toast.success("Job Has Been ended successfully");
          setreviewModel(true);
        }
        // setTimeout(() => {
        //   navigate("/services");
        // }, 3000);
      })
      .catch((e) => toast.error(e));

    setloader(false);
    setIsModalOpen(false);

    // console.log(apiReqest, "aoo");
  };
  const handleCancel = (ui) => {
    setui(ui);
    setIsModalOpen(false);
  };
  const handleExtendJob = (data) => {
    // console.log(data, "from ex");
    setIsModalOpen(false);
  };
  // console.log(booking_data.booking_id,'shiushsuih')

  const ChatWithGuard = async (driver_id) => {
    setChatloader(true);
    setappearChat(!appearChat);
    setselectDriver(driver_id);
    setconversations([]);

    var chat_identity = "customer" + driver_id + "InstaGuard";
    var room_identity =
      "Channel:" +
      LoginData.id +
      "," +
      driver_id +
      "," +
      booking_data.booking_id;
    const response = await axios.post(
      baseUrl + "chatAccessToken?identity=" + chat_identity
    );
    const { data } = response;
    // console.log(data)
    // setToken(data.token);
    const client = await Chat.Client.create(data.token);
    // console.log(client);

    client.on("tokenAboutToExpire", async () => {
      const { token } = ChatWithGuard(driver_id);
      client.updateToken(token);
      // console.log("expToken", token);
    });

    client.on("tokenExpired", async () => {
      const { token } = ChatWithGuard(driver_id);
      client.updateToken(token);
      // console.log("expToken", token);
    });

    client.on("channelJoined", async (channel) => {
      const messages = await channel.getMessages();
      const findChanel = await channel.uniqueName;
      if (findChanel == room_identity) {
        // console.table(messages.items.map(e=> e.body),'nsns')
        setconversations(messages.items || []);
      }
      // console.log(await channel.uniqueName)

      // scrollToBottom();
      setTrigger(!trigger);
    });

    try {
      const channel = await client.getChannelByUniqueName(room_identity);
      setchannel(channel);
      joinChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: room_identity,
          friendlyName: room_identity,
        });

        joinChannel(channel);
        setchannel(channel);
      } catch {
        throw new Error("Unable to create channel, please reload this page");
      }
    }
    setChatloader(false);

    return data;
  };
  const handleMessageAdded = (message) => {
    console.log("new message", message.body);
    setconversations((pre) => [...pre, message]);
    setTimeout(() => {
      setTrigger(!trigger);
    }, 200);
  };

  const sendMessage = (newMessage) => {
    if (newMessage) {
      channel.sendMessage(String(newMessage).trim());
      FetchApi('message_notify', {
        id: selectDriver,
        type: 'driver',
        mode: 'chat',
        message: newMessage
      })
      setTimeout(() => {
        setTrigger(!trigger);
      }, 100);

      // scrollToBottom();
    }
  };

  useEffect(() => {
    // Subscribe to the "messageAdded" event
    if (channel != null) {
      // console.log(channel.channelState,'mmm')
      channel.on("messageAdded", handleMessageAdded);

      return () => {
        channel.off("messageAdded", handleMessageAdded);
      };
    }

    // Unsubscribe when the component is unmounted
  }, [channel]);

  const joinChannel = async (channel) => {

    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
  };
  const closeChatModel = () => {
    setappearChat(false)
    leftChannel(channel)
  }
  const leftChannel = async (channel) => {

    // if (channel.channelState.status !== "joined") {
    await channel.leave();
    // }
  };
  //   useEffect(() => {
  //  console.table(conversations)
  //   }, [conversations])
  const onChangeFeedback = (data, i, type) => {
    feedback[i][type] = data;
    setfeedback([...feedback]);
  };
  const feedBackSumbit = () => {
    // console.log(feedback,'eeeded')
    setloader(true);
    const { data } = FetchApi("MultiCustomerFeedbackStore", {
      job_id: booking_data?.booking_id,
      from_id: LoginData?.id,
      ratings_data: JSON.stringify(feedback),
    });
    console.log(data);
    feedBackCancel();
  };
  const feedBackCancel = () => {
    setloader(true);
    toast.success("Job Has Been ended successfully");
    setTimeout(() => {
      // try {
      //   set(driverLocation, null);
      // } catch (error) {
      //   console.log(error);
      // }
      navigate("/services");
    }, 2000);
    // console.log(feedback,'eeeded')
  };
  const [twilioClient, setTwilioClient] = useState(null);

  const callWithGuard = (guard) => {
    setGuard(guard);
    setcallModel(true);
    setCallloader(true);
    var toIdentity = "InstaGuard_driver" + guard?.driver_id;
    makeCall(toIdentity);
    // setcallerCount((prev) => prev + 1);
    FetchApi('message_notify', {
      id: guard?.driver_id,
      type: 'driver',
      mode: 'call',
      message: 'Call form User ' + LoginData?.first_name
    })
  };
  const closeCallModel = () => {
    setcallModel(false);
    setTime(0);
    stopTimer();
    if (twilioClient) {
      twilioClient.disconnectAll();
    }
  };


  const listen = async () => {
    const response = await axios.post(
      baseUrl + "accesstoken?identity=" + chat_identity
    );
    const { data } = response;
    setTwilioClient(await Device.setup(data.token));
    if (Device) {
      setCallloader(false);
      Device.ready(() => {
        setCallStatus("Ready to make and receive calls");
      });

      Device.error((error) => {
        console.log(`Twilio Error: ${error.message}`);
        closeCallModel()
      });

      Device.connect((connection) => {
        // console.log(connection);

        setCallStatus(`Connected to ${connection.parameters.From}`);
      });

      Device.disconnect(() => {
        setCallStatus("Call ended");
      });
      Device.on("tokenWillExpire", () => {
        listen();
        Device.updateToken(data.token);
      });

      Device.incoming((connection) => {
        setCallStatus(`Incoming call from ${connection.parameters.From}`);
      });
    }
  };

  const formattedTime = new Date(time * 1000).toISOString().substr(11, 8);
  const makeCall = (id) => {
    startTimer();
    setCallloader(false);
    if (twilioClient) {
      const params = {
        to: id,
      };
      const outgoingCall = twilioClient.connect(params);

      outgoingCall.on("accept", () => {
        // console.log("Outgoing call accepted");
        setCallStatus("Call accepted");
      });
      outgoingCall.on("connected", () => {
        // console.log("ewgweg call awegegccepted");
        setCallStatus("Call egweg");
      });
      // console.log(outgoingCall);

      outgoingCall.on("disconnect", (e) => {
        console.log("Outgoing call disconnected", e);
        toast.error("Call Disconnected");
        setCallStatus("Call ended");
        closeCallModel();
      });

      outgoingCall.on("error", (error) => {
        console.error("Error during outgoing call:", error);
        setCallStatus(`Call failed: ${error.message}`);
        if (error.code == 31201) {
          toast.error("Microphone not Found");
        } else {
          toast.error(error.message);
        }
      });
    }
  };
  const ChatWithGuardSupport = useCallback((driverId) => {
    // Handle click logic here
    ChatWithGuard(driverId);
  }, []);
  const CallWithGuardSupport = useCallback((driverData) => {
    callWithGuard(driverData);
  }, []);


  const handleDriverLocation = async (driver_id) => {
    const locateAll = ref(firebase, "/drivers_location/" + driver_id);
    const snap = await get(locateAll);
    const location = snap.val();
    setcustomerState({lat: parseFloat(location?.l[0]), lng: parseFloat(location?.l[1]), });
  }

  return (
    <div className="container mt-5">
      {/* <ToastContainer /> */}
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <button
          className="text-white  rounded-2 some_btn   bg-app-color"
          onClick={() =>
            navigate("/bookingDetails", {
              state: 1,
            })
          }
        >
          <IoMdArrowDropleftCircle />
          Back
        </button>

        <div className="d-flex gap-2">
          {show && (
            <button
              onClick={() => showModal("end")}
              // onClick={()=>setreviewModel(!reviewModel)}
              className="some_btn bg-base-color rounded-2 text-white"
            >
              <MdDone className="align-self-center" />
              End Job
            </button>
          )}
          {!show && (
            <button
              onClick={() => showModal(isExtend ? "extent_view" : "extend")}
              className={`some_btn ${isExtend ? "bg-base-color" : "bg-info"
                }   rounded-2 text-white`}
            >
              <HiClock />
              {isExtend ? "Extend View" : "Extend Job"}
            </button>
          )}
          {showcancel && (
            <button
              onClick={() => showModal("cancel")}
              className="bg-danger rounded-2 some_btn text-white "
            >
              <MdCancel />
              Cancel Job
            </button>
          )}
        </div>
      </div>
      <BookingModel
        action={isModalOpen}
        load={loader}
        type={ui}
        close={handleCancel}
        data={extend}
        Onok={
          ui == "end"
            ? handleOk
            : ui == "extend"
              ? handleExtendJob
              : handleCancelTrip
        }
      />
      <Modal
        centered
        title={""}
        open={reviewModel}
        onOk={null}
        onCancel={null}
        className=""
        footer={null}
        closable={false}
      >
        <div className="d-flex justify-content-center flex-column  ">
          {loader ? (
            <div className="d-flex justify-content-center ">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="review_model">
                <div className=" base-color fs-5 text-center  fw-bold ">
                  Please give feedback on the  <span className="app-color">Insta-Guard officer</span>
                </div>
                <div className="">
                  {feedback.map((e, i) => {
                    // console.log(e)
                    return (
                      <div className="d-flex row align-items-center   border my-3 p-2">
                        <div className="col-3 d-flex flex-column gap-1 align-items-center p-0">
                          <img
                            src={
                              e.avatar == null
                                ? require("../asserts/defaultpic.jpg")
                                : imageUrl + e.avatar
                            }
                            // src={require("../asserts/defaultpic.jpg")}
                            alt=""
                            className="img-fluid driver-image rounded-circle"
                            srcset=""
                          />
                          <span className="driver-name">
                            {e.first_name}
                          </span>
                        </div>

                        <div className="input col-9">
                          <Rate
                            id="rating"
                            className="my-3"
                            allowHalf
                            value={e.rating}
                            key={i}
                            onChange={(e) =>
                              onChangeFeedback(e, i, "rating")
                            }
                          />{" "}
                          <br />
                          <Input.TextArea
                            placeholder="Write Review"
                            key={i + "feed"}
                            id="feedback"
                            autoSize={{
                              minRows: 3,
                              maxRows: 6,
                            }}
                            value={e.feedback}
                            className="w-100"
                            onChange={(e) =>
                              onChangeFeedback(
                                e.target.value,
                                i,
                                "feedback"
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex justify-content-center  align-items-center gap-2 mt-2 mb-3">
                <button
                  className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
                  onClick={feedBackCancel}
                >
                  Cancel
                </button>
                <button
                  onClick={feedBackSumbit}
                  className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
                >
                  OK
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>

      <div className="row bg-white rounded-2 ">
        <div className="col-12 col-lg-6 col-md-6">
          <div className="border-bottom text-center p-2 base-color fw-bold">
            Guard <span className="app-color"> Details</span>
          </div>
          <div className="list-driver">
            <div className="mt-3 my-2">
              {drivers.map((accordion) => (
                <Accordion
                  key={accordion.id}
                  // expanded={expanded === accordion.id}
                  expanded={expanded[accordion.id]}
                  onChange={() => handleChange(accordion.id)}
                  onClick = {() => handleDriverLocation(accordion.driver_id)}
                  className="my-1 px-2"
                
                >
                  <AccordionSummary
                    expandIcon={
                      <IoIosArrowDropdown className="bg-white text-black fs-4 " />
                    }
                    aria-controls={`${accordion.id}-content`}
                    id={`${accordion.id}-header`}
                    className="m-0 p-1 "
                  >
                    <div className="row d-flex align-items-center  w-100">
                      <div className="col-3 d-flex flex-column align-items-center">
                        <img
                          src={
                            accordion.driver_avatar == ""
                              ? DefaultImage
                              : imageUrl+ 'uploads/' + accordion.driver_avatar
                          }
                          className="card-img "
                          alt=""
                        />
                        <span className="text-center  text-muted text-response">
                          {accordion.driver_first}
                        </span>
                      </div>
                      <div className="col-9 ">
                        <div className="text-table">
                          <div className="d-flex gap-4 my-1 align-items-center">
                            <p className="m-0 base-color ">Mobile</p>
                            <p className="m-0">:</p>
                            <p className="m-0 text-muted">
                              {accordion.driver_mobile}
                            </p>
                          </div>
                          <div className="d-flex gap-4 my-1 align-items-center">
                            <p className="m-0 base-color">Status</p>
                            <p className="m-0">:</p>
                            <p
                              className="m-0 text-white px-2 rounded-2 fw-bold lable ms-2"
                              style={{
                                background: getStatusInfo(
                                  parseInt(accordion.trip_status)
                                ).externalColor,
                              }}
                            >
                              {
                                getStatusInfo(parseInt(accordion.trip_status))
                                  .name
                              }
                            </p>
                          </div>{" "}
                          <div className="d-flex gap-4 my-1 align-items-center">
                            <p className="m-0 base-color">Rating</p>
                            <p className="m-0">:</p>
                            <p className="m-0">
                              <Rate
                                value={accordion.ratings}
                                className="m-0 p-0"
                                style={{
                                  fontSize: 12,
                                  position: "relative",
                                  // top: -8,
                                }}
                                disabled
                              />
                            </p>
                          </div>
                          <div className="d-flex  gap-3 my-1 align-items-center">
                            <p className="m-0 base-color">Accept Time</p>
                            <p className="m-0">:</p>
                            <p className="m-0 text-muted">
                              {accordion.accept_time}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="my-1">
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <div className=" p-2 base-color fw-bold mb-2">
                          Job <span className="app-color"> Status</span>
                        </div>
                        <div className="d-flex gap-2">
                          <span
                            onClick={() => ChatWithGuardSupport(accordion.driver_id)}
                            className="d-flex gap-1 align-items-center btn btn-success"
                          >
                            <AiOutlineMessage className="font-sm " />
                            Message
                          </span>
                          <button
                            disabled={callModel}
                            onClick={() => CallWithGuardSupport(accordion)}
                            className="d-flex border-0 gap-1 align-items-center px-2 text-white rounded-2  bg-app-color"
                          >
                            {/* <PiPhoneCall className="font-sm " /> */}
                            <AiFillPhone className="font-sm" />
                            Call
                          </button>
                        </div>
                      </div>

                      <Steps
                        direction="vertical"
                        size="small"
                        current={
                          parseInt(accordion.trip_status) == 6
                            ? 5
                            : parseInt(accordion.trip_status) - 1
                        }
                        items={[
                          {
                            title: "Guard Accept Your job Request",
                            color: "green",
                            description: `on ${accordion.accept_time}`,
                          },
                          {
                            title: "Guard On the way to your Location",
                            description: ` ${accordion.start_time ?? ""}`,
                          },
                          {
                            title: "Guard has arrived at your Location ",
                            description: ` ${accordion.reach_time ?? ""}`,
                          },
                          {
                            title: "Guard Begin your Job",
                            description: ` ${accordion.service_start_time ?? ""
                              }`,
                          },
                          {
                            title: "Guard Complete your Job",
                          },
                        ]}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-md-6 p-0">
          <TrackMap
            track={state}
            state={customerstate}
            profile={LoginData}
          />
          <div>
            <ChatBox
              action={appearChat}
              trigger={trigger}
              sendMsg={sendMessage}
              message={conversations}
              setAction={closeChatModel}
              loader={chatloader}
              driver_id={selectDriver}
            />
          </div>
        </div>
      </div>
      <Modal
        centered
        title={""}
        open={callModel}
        onOk={null}
        onCancel={null}
        className=""
        footer={null}
        closable={false}
      >
        <div className="d-flex justify-content-center flex-column">
          {callLoader ? (
            <div className="d-flex justify-content-center ">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="review_model">
                <div className=" base-color fs-5 text-center  fw-bold ">
                  Guard
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img
                      src={
                        Guard?.driver_avatar == ""
                          ? DefaultImage
                          : imageUrl + Guard?.driver_avatar
                      }
                      className="card-img "
                      alt=""
                    />
                  </div>

                  <div className="text-center  text-muted text-response">
                    {Guard?.driver_first}
                  </div>
                  <TwilloCall
                    hang={closeCallModel}
                    to={Guard?.driver_id}
                    sts={callStatus}
                    // onCall={callLoader}
                    time={formattedTime}
                  />
                </div>
              </div>

              {/* <div className="d-flex justify-content-center  align-items-center gap-2 mt-2 mb-3">
                <button
                  className="border-0 p-1 text-white bg-app-color rounded-2 px-2"
                  onClick={closeCallModel}
                >
                  Cancel
                </button>
                <button
                  onClick={feedBackSumbit}
                  className="border-0 p-1 text-white bg-base-color rounded-2 px-3"
                >
                  OK
                </button>
              </div> */}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default MapView;
