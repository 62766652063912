import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import DefaultImage from '../asserts/defaultpic.jpg'
import {  imageUrl } from "../config/Const";

function TrackMap({ track, state, profile }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBxwzbehjrT3hVubyS9VWBPBTvglagBDv0",
  });
  // console.log(track, 'sssffs')
  const [mapMarker, setmapMarker] = useState(track ?? []);
  const [selectedMarker, setSelectedMarker] = useState(null);
  
  const onSelectMarker = (marker) => {
    setSelectedMarker(marker);
  };

  const onCloseInfoWindow = () => {
    setSelectedMarker(null);
  };
  if (isLoaded) {
    var cusicon = {
      url: profile.avatar && profile.avatar != "uploads/" ? imageUrl + profile.avatar : DefaultImage,
      scaledSize: new window.google.maps.Size(40, 40), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(0, 0), // anchor
      shape: { coords: [17, 17, 18], type: "circle" },
      optimized: true,
    };
  }

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(state);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    const fun = async () => setmapMarker(await track);
    return () => fun();
  }, [track]);

  const destructure = (data) => {
    const { lat, lng } = data
    return { lat, lng }
  }

  const setIcon = (data) => {
    let driverIcon = {
      url: data.profile ? imageUrl + data.profile : DefaultImage, // url
      scaledSize: new window.google.maps.Size(40, 40), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(0, 0), // anchor
      shape: { coords: [20, 20], type: "circle" },
      optimized: true,
    };

    return driverIcon;
  }

  function calculateArrivalTime(lat1, lon1, lat2, lon2, speed) {
    // Radius of the Earth in kilometers
    const R = 6371;

    // Convert latitude and longitude from degrees to radians
    const radLat1 = toRadians(lat1);
    const radLon1 = toRadians(lon1);
    const radLat2 = toRadians(lat2);
    const radLon2 = toRadians(lon2);

    // Haversine formula
    const dLat = radLat2 - radLat1;
    const dLon = radLon2 - radLon1;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(radLat1) * Math.cos(radLat2) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    // Calculate arrival time in minutes
    const timeInHours = distance / speed;
    const timeInMinutes = timeInHours * 60;

    return timeInMinutes;
  }

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  return (
    <div className={map}>
      {isLoaded && (
        <GoogleMap
          center={state}
          zoom={6}
          fullscreenControl={false}
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapTypeId={window.google.maps.MapTypeId.SATELLITE}
          mapContainerStyle={{
            width: "100%",
            height: "80svh",
            borderRadius: "10px",
          }}
        // options={{
        //   fullscreenControl: false,
        //   mapTypeId: window.google.maps.MapTypeId.SATELLITE,
        // }}
        >
          {mapMarker.map((locate) => {
       
            return <Marker position={destructure(locate)} icon={setIcon(locate)} onClick={() => onSelectMarker(locate)} />;
          })}
          {selectedMarker && (
            <InfoWindow
              position={selectedMarker}
              onCloseClick={onCloseInfoWindow}
            >
              <div>
                <p>{selectedMarker?.fname}</p>
                {
                  Number(calculateArrivalTime(state.lat, state.lng, selectedMarker?.lat, selectedMarker?.lng, 30 ?? 0)) != 0 ? <p>Estimated arrival time: {Number(calculateArrivalTime(state.lat, state.lng, selectedMarker?.lat, selectedMarker?.lng, 30) ?? 0).toFixed()} minutes </p> : <p> Guard Arrived </p>
                }

              </div>
            </InfoWindow>
          )}
          <Marker position={state} icon={cusicon} />
          {/* <Polyline
            path={[state, track]}
            strokeColor={"#832B67"}
            strokeOpacity={0.8}
            strokeWeight={2}
          /> */}
        </GoogleMap>
      )}
    </div>
  );
}

export default TrackMap;
