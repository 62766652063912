import axios from "axios";
import { baseUrl } from "./Const";

export default async function FetchApi(apiUrl, params = {}) {
  try {
    const response = await axios.post(baseUrl + apiUrl, params, {
      headers: {
        "api-key": "3895c3daf1b89db494abc5e2953a4c92890c826f",
        "Content-Type": "application/json",
      },
    });
    // Handle the response data
    // console.log("API Response:", response.data);
    // You can return the data or perform other actions as needed
    return response.data;
  } catch (error) {
    // Handle errors
    console.error("Error fetching data:", error.message);
    // return error;
    throw error; // Rethrow the error if needed
  }
}
