import { Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { ImPhoneHangUp } from "@react-icons/all-files/im/ImPhoneHangUp";

import { useSelector } from "react-redux";
import store from "../Redux/Store";
import DefaultImage from "../asserts/defaultpic.jpg";
import { baseUrl } from "../config/Const";

const Device = require("twilio-client").Device;

const TwiloReceiver = () => {
  const [incomingConnection, setIncomingConnection] = useState(null);
  const [twilioClient, setTwilioClient] = useState(null);
  const LoginData = useSelector((state) => state.LgnData);
  const [callStatus, setCallStatus] = useState("");
  const [changeUi, setchangeUi] = useState(false);
  const callReceiver = useSelector((state) => state.callReciever);
  var chat_identity = "InstaGuard_user" + LoginData?.id;

  // console.log(callReceiver, "hjhjhj");
  const setcallModel = (value) => {
    store.dispatch({ type: "trigger", payload: value });
  };
  useEffect(() => {
    if (twilioClient) {
      twilioClient.incoming((connection) => {
        setIncomingConnection(connection);
        console.log(`Incoming call from ${connection.parameters.From}`);
        setcallModel(true);
        // console.log("calllll");
        // Set up event listeners for call acceptance and rejection
        console.log(connection.status());

        connection.on("accept", (connection) => {
          console.log("acperr");
        });
        connection.on("disconnect", (connection) => {
          console.log("disconnect");
        });
        connection.on("cancel", () => {
          console.log("Call canceled");
          setcallModel(false);
        });
        connection.on("connect", () => {
          console.log("Connected");
        });

        connection.on("reject", () => {
          setCallStatus("Call declined");
          setcallModel(false);
          console.log("call dec");
          // Your logic for declined call
        });
      });
    }
    // console.log("calllllllllll", onCall);
  }, [twilioClient]);
  useEffect(() => {
    listen();
  }, []);

  const listen = async () => {
    const response = await axios.post(
      baseUrl + "accesstoken?identity=" + chat_identity
    );
    const { data } = response;
    setTwilioClient(await Device.setup(data.token));

    if (Device) {
      Device.ready(() => {
        // console.log("Ready to make and receive calls");
      });

      Device.error((error) => {
        setcallModel(false);
        setCallStatus(`Twilio Error: ${error.message}`);
      });

      Device.connect((connection) => {
        // console.log(connection);

        setCallStatus(`Connected to ${connection.parameters.From}`);
      });

      Device.disconnect(() => {
        setCallStatus("Call ended");
        setcallModel(false);
      });
      Device.on("tokenWillExpire", () => {
        listen();
        Device.updateToken(data.token);
      });

      Device.incoming((connection) => {
        setIncomingConnection(connection);
        setcallModel(true);
      });
    }
  };
  const [timeDisplay, setTime] = useState(0);
  let timer;
  // Function to start the timer
  const startTimer = () => {
    setTime(0);
    timer = setInterval(() => {
      setTime((prevTime) => prevTime + 1); // Increment the time by one second
    }, 1000);
  };

  // Function to stop the timer
  const stopTimer = () => {
    clearInterval(timer); // Clear the interval
  };
  const hangUpCall = () => {
    setchangeUi(!changeUi);
    setTime(0);
    setcallModel(false);
    stopTimer();
    if (twilioClient) {
      twilioClient.disconnectAll();
    }
  };
  // Function to accept the incoming call
  const acceptCall = () => {
    if (incomingConnection) {
      incomingConnection.accept();
    }
    startTimer();
    setchangeUi(!changeUi);
  };

  // // Function to decline the incoming call
  const declineCall = () => {
    if (incomingConnection) {
      incomingConnection.reject();
      setCallStatus("Call declined");
      setTime(0);
      setcallModel(false);
    }
  };
  const formattedTime = new Date(timeDisplay * 1000)
    .toISOString()
    .substr(11, 8);

  return (
    <div className="m-0 ">
      <Modal
        centered
        title={""}
        open={callReceiver}
        onOk={null}
        onCancel={null}
        className=""
        footer={null}
        closable={false}
      >
        <div className="d-flex justify-content-center flex-column">
          <>
            <div className="review_model">
              <div className=" base-color fs-5 text-center  fw-bold ">
                Call Form Guard
              </div>
              <div className="d-flex flex-column  align-items-center">
                <div>
                  <img src={DefaultImage} className="card-img " alt="" />
                </div>

                {changeUi ? (
                  <div className="m-0 d-flex justify-content-center flex-column align-items-center">
                    <p className="text-muted mb-0">Connected</p>
                    <p className="app-color fs-5 mb-0">{formattedTime}</p>

                    <button
                      onClick={hangUpCall}
                      className="p-3 rounded-circle text-white bg-danger border-0 fs-6 "
                    >
                      {/* <ImPhoneHangUp /> */}cut
                    </button>
                  </div>
                ) : (
                  <div className="text-center  text-muted text-response">
                    <p className=" text-muted">Ringing...</p>

                    <div className="d-flex gap-5">
                      <button
                        className="p-1 d-flex justify-content-center gap-2 align-items-center px-1 rounded-2 text-white bg-success border-0 fs-6"
                        onClick={acceptCall}
                      >
                        <span>
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57a.84.84 0 0 0-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.149 15.149 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1z"></path></svg>
                        </span>
                        Accept
                      </button>
                      <button
                        onClick={declineCall}
                        className="p-1 d-flex justify-content-center gap-2 align-items-center px-1 rounded-2 text-white bg-danger border-0 fs-6 "
                      >
                        Decline
                        <ImPhoneHangUp />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
};

export default TwiloReceiver;
