import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import store from '../Redux/Store';

function NavigatorPage() {
    const currentUrl = useParams();
    const navigate = useNavigate();
    const LoginData = useSelector((state) => state.LgnData);

    useEffect(() => {
        console.log(currentUrl);
        const setObj = {
            open: true,
            driver_id: currentUrl?.driver,
            bookingID: currentUrl?.booking
        }
        if (LoginData.id && currentUrl.driver != undefined) {
            store.dispatch({ type: 'push_appear', payload: setObj });

        }
        navigate('/')
    }, [])

    return (
        <div className='' style={{ position: 'relative' }}>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    bottom: 0,
                    transform: "translate(-50%, -50%)",
                    // opacity: "0.5",
                    zIndex: 999,
                }}
            >
                <div
                    className="d-flex align-items-center gap-3 p-3  text-white letter-space"
                    style={{
                        background: "rgba( 131, 43, 103, 0.55 )",
                        boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
                        backdropFilter: "blur( 20px )",
                        // "-webkit-backdrop-filter": "blur( 20px )",
                        borderRadius: "10px",
                        border: "1px solid rgba( 255, 255, 255, 0.3 )",
                        height: 100,
                    }}
                >
                    <CircularProgress
                        style={{
                            color: "#832b67",
                        }}
                    />{" "}
                    Loading
                </div>
            </div>
        </div>
    )
}

export default NavigatorPage