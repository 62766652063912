import { onMessage } from '@firebase/messaging';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import store from '../Redux/Store';
import DefaultImage from "../asserts/defaultpic.jpg";
import { messaging, requestForToken } from '../config/Firebase';

console.log(messaging, 'me');
const Notification = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const navigater = useNavigate();
  const redux_data = useSelector((state) => state.ChatReciever);


  const appearChat = () => {
    const setObj = {
      open: true,
      driver_id: notification?.data.driver_id,
      bookingID: notification?.data.booking_id
    }
    toast.dismiss(4620)
    store.dispatch({ type: 'push_appear', payload: setObj });
  }
  const LoginData = useSelector((state) => state.LgnData);


  useEffect(() => {
    if (notification?.body) {
      if (!redux_data.open && LoginData.id) {
        notify()
        setNotification({ title: '', body: '' })
      }
      // console.log('call');
    }
  }, [notification])
  useEffect(() => {

    if (LoginData.id) {
      requestForToken(LoginData?.id);
    }
    if (messaging != null) {
      onMessage(messaging, (payload) => {
        setNotification({ title: payload?.notification?.title, body: payload?.notification?.body, data: payload.data });

        console.log(payload, 'messageChek');

      });
    }
    // console.log('logg from notifcation');


  }, [])



  const notify = () => toast(<ToastDisplay />, {
    position: 'bottom-center',
    id: 4620

  })

  // toast.custom((t) => (
  //   <div
  //     className={`${t.visible ? 'fade-in' : 'fade-out'
  //       } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto  border rounded-2 pointer`}
  //     onClick={() => appearChat(t.id)}

  //   >

  //     <p className='px-2 border-bottom p-2'>Call From Guard</p>
  //     <div className="p-2 mt-2 ">
  //       <div className="d-flex gap-2">
  //         <div className="pt-1">
  //           <img
  //             height={50}
  //             width={50}
  //             className=" rounded-circle"
  //             src={DefaultImage} alt=""
  //           />
  //         </div>
  //         <div className="ml-3 flex-1">
  //           <p className=" m-0 p-0">
  //             {notification.title}
  //           </p>
  //           <p className="mt-1 text-sm ">
  //             {notification.body}
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="d-flex border-left border-gray-200">
  //       {/* <button
  //         onClick={() => toast.dismiss(t.id)}
  //         className="w-full border border-transparent rounded-0 rounded-right-lg p-4 d-flex align-items-center justify-content-center text-sm font-weight-medium text-indigo-600 hover-text-indigo-500 focus-outline-0 focus-ring-2 focus-ring-indigo-500"
  //       >
  //         Close
  //       </button> */}
  //     </div>
  //   </div>
  // ));
  const closeToast = () => {
    toast.dismiss(4620)
  }
  function ToastDisplay() {
    return (
      <div className='w-100 pointer' onClick={notification?.data.mode == 'chat' ? appearChat : closeToast}>
        <p className='fw-bold base-color text-center'> {notification?.data.mode == 'chat' ? 'Message' : 'Call'}  <span className='app-color'> From Guard</span></p>
        <hr />
        <div className='bg-muted'>
          <div className="p-2 mt-2 ">
            <div className="d-flex px-3 gap-4">
              <div className="pt-1 ">
                <img
                  height={50}
                  width={50}
                  className=" rounded-circle"
                  src={DefaultImage} alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                <p className=" m-0 p-0 base-color">
                  {notification.title}
                </p>
                <p className="mt-1 text-sm text-black ">
                  {notification.body}
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

    );
  };


  return (
    <Toaster />
  )
}

export default Notification