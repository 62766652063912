import { Backdrop } from "@mui/material";
import { off, onValue, ref, update } from "firebase/database";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Police from "../asserts/output-onlinegiftools1.gif";
import FetchApi from "../config/ApiCal";
import { firebase } from "../config/Firebase";
import "../styles/booking.css";
function Loading() {
  const LoginData = useSelector((state) => state.LgnData);
  const navigate = useNavigate();
  const location = useLocation();

  let [timedOut, setTimedOut] = useState(null);
  let timer = location.state?.timeout;
  console.log(timer, "time");
  const timedOutRef = useRef(timedOut);
  const [timeLeft, setTimeLeft] = useState(location.state?.timeout);

  useEffect(() => {
    // Start the Firebase listener
    startFirebaseListener();
    const countdownInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownInterval); // Clear interval when time reaches 0
        }
        return prevTime - 1;
      });
    }, 1000);

    
    // Stop the Firebase listener after 30 seconds
    timedOutRef.current = setTimeout(() => {
      const listener = ref(firebase, "users/" + LoginData.id + "/trips");
      off(listener, "value");
      FetchApi("customerTimeout", {
        booking_id: location.state?.booking_id,
      });
      toast.error("No guards are available within your area currently");
      setTimeout(() => {
        navigate("/services");
      }, 2000);
    }, timer * 1000); // 150 seconds in milliseconds
    return () => {
      clearInterval(countdownInterval);
      clearTimeout(timedOutRef.current);
      const listener = ref(firebase, "users/" + LoginData.id + "/trips");
      off(listener, "value");
    };
  }, []);

  const startFirebaseListener = () => {
    const acceptStatus = ref(firebase, "users/" + LoginData.id + "/trips");

    onValue(acceptStatus, (snapshot) => {
      if (snapshot.val().accept_status == "yes") {
        clearTimeout(timedOutRef.current);
        toast.success("Your booking was accepted by the Guard");
        setTimeout(() => {
          UpdateStatus();
        }, 2000);
      }
    });
  };

  const UpdateStatus = () => {
    const postData = {
      accept_status: "0",
    };
    const updates = {};
    updates["users/" + LoginData.id + "/trips"] = postData;
    update(ref(firebase), updates);

    navigate("/bookingDetails");
  };

  return (
    <div className="" style={{ height: "100vh" }}>
      <Backdrop
        open={true}
        className="d-flex flex-column  justify-content-center  align-items-center "
        style={{ zIndex: 99 }}
      >
        <img
          src={Police}
          alt=""
          className="d-block loader-img"
        // style={{ height: "300px" }}
        />
        <p className="base-color fw-bold  fs-2 letter-space mt-4 d-flex  align-items-center gap-4 ">
          Searching For Guard
          <div class="snippet mt-2" data-title="dot-typing">
            <div class="stage">
              <div class="dot-typing"></div>
            </div>
          </div>

        </p>
        <span className="text-white fw-bold letter-space">
          Time remaining: {timeLeft} seconds
        </span>
        <span className="text-white text-loading fw-bold letter-space">
          Don't Close this Window Request has been processing{" "}
        </span>
      </Backdrop>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default Loading;
