import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import React from 'react';
import { useNavigate } from 'react-router-dom';


function Howitworks() {
    const navigate = useNavigate();

    return (
        <div className='container'>
            <div className='work-head'
            ><small role='button' onClick={() => {
                navigate('/')
                window.scrollTo({ top: 0, behavior: "smooth" });
            }}>Home </small>
                <IoIosArrowForward className='arr-ico' color='#2a2f83' size={22} />
                &nbsp;<span>How it Works</span></div>
            <div>
                <p className='work-sub-head'>How Insta Guard Works</p>
                <p className='work-desc'>How it works</p>
                <p className='work-sub-desc text-muted'>
                    Our officers are trained in keeping records. Evidence is essential for the authorities to do anything about it.
                    Has the landlord been contacted or the property agent ? By, giving specific information to Insta-guard this gives our officers a clearer picture.
                    We are a 3 rd party which has been hired in to assist and support our customer’s request.
                    All logs and conversations with the neighbour are stored and over time a file starts to build. Would you be interested in mediation service?
                    Not all residents have passed this point and mediation can work. We understand you want this resolved and by opening a channel can sometimes be a better solution for both parties.<br></br>
                    If this has gone past this point, then our expert security specialist is brought in. Insta-guard are experts trained in this area and after the consultation we then decide on a plan of action which will determine what we will do and how much you will pay?
                    Insta-guard works over the duration by being a presence within your community and recording harassment or intimidation by your neighbour. All incidents will be recorded and passed onto authorities.<br></br>
                    Insta-guard are trained specialists within this arena who will tailor the best package to support your problem.
                    Our security consultants will come in uniform or in plain clothes. We are professionals who do this daily.
                    Insta-guard, is there to help and to serve and to be a benefit in the community. Reach out today if you need a listening ear and wish to book a consultation to stop nuisance neighbours.
                </p>
            </div>
        </div>
    )
}

export default Howitworks